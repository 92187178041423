import { createSlice } from "@reduxjs/toolkit";
import { BillService } from "services/BillService";
import { removeIdentity, geneUniqueKey, emptyObject } from "utils";
import pick from "lodash/pick";
import { PARAMS_LISTING } from "constants/variables";
const initialState = {
  isLoading: false,
  success: false,
  billExpress: [],
  paging: {},
  bill: {},
  issues: [],
  billCp: {},
  billPending: [],
  loadCp: false,
  isCp: false,
  billFromFile: {},
  billRevokeFile: {},
  billReceiverFile: {},
  billVerifed: {},
  errorMessage: "",
  billByPhone: [],
  ticket: {},
  stuck_list: [],
  dashboard: {},
};

const countPersen = (income, paid) => {
  const total = paid + income;
  if ([!Boolean(total), Number.isNaN(total)].some(Boolean)) return 0;
  return (paid / total) * 100;
};

const slice = createSlice({
  name: "bill",
  initialState,
  reducers: {
    startGetting(state) {
      state.isLoading = true;
      state.billExpress = [];
      state.paging = {};
    },
    startGetBill(state) {
      state.bill = {};
    },
    startGetBillCP(state) {
      state.billCp = {};
      state.loadCp = false;
      state.isCp = false;
    },
    finallyBillCp(state) {
      state.loadCp = true;
    },
    startGetIssuse(state) {
      state.issues = [];
    },
    startGetStuckImageBill(state) {
      state.stuck_list = [];
    },
    startGetBillPending(state) {
      state.isLoading = true;
      state.billPending = [];
      state.paging = {};
    },
    getBillExpressSuccess(state, action) {
      const { payload } = action;
      state.billExpress = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    getBillSuccess(state, action) {
      const { payload } = action;
      state.bill = payload.data;
    },
    getIssueBillSuccess(state, action) {
      const { payload } = action;
      state.issues = payload.data;
    },
    getStuckImageBillSuccess(state, action) {
      const { payload } = action;
      state.stuck_list = payload.data;
    },
    getBillCP(state, action) {
      const { payload } = action;
      if (payload.success) {
        state.isCp = true;
      }
      state.billCp = payload.data;
    },
    getBillPendingSuccesss(state, action) {
      const { payload } = action;
      state.billPending = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    doneGetting(state) {
      state.isLoading = false;
    },
    startSendFile(state) {
      state.billFromFile = {};
      state.errorMessage = "";
    },
    getBillFile(state, action) {
      const { payload } = action;
      const cloneBill = { ...payload?.data };
      state.billFromFile = Object.keys(cloneBill).reduce((acc, i) => {
        const obj = cloneBill[i];
        const newAcc = cloneBill[i].data.map(
          (i) => (i = { ...i, key: geneUniqueKey() })
        );
        const newObj = { ...obj, count: obj.count, data: newAcc };
        acc[`${i}`] = newObj;
        return acc;
      }, {});
      if (!payload?.success) {
        state.errorMessage = payload?.message ?? "";
      }
    },
    // startSendFile(state) {
    //   state.billRevokeFile = {};
    //   state.errorMessage = "";
    // },
    getBillRevokeFile(state, action) {
      const { payload } = action;
      const cloneBill = { ...payload?.data };
      state.billRevokeFile = Object.keys(cloneBill).reduce((acc, i) => {
        const obj = cloneBill[i];
        const newAcc = cloneBill[i].data.map(
          (i) => (i = { ...i, key: geneUniqueKey() })
        );
        const newObj = { ...obj, count: obj.count, data: newAcc };
        acc[`${i}`] = newObj;
        return acc;
      }, {});
      if (!payload?.success) {
        state.errorMessage = payload?.message ?? "";
      }
    },
    getBillReceiverFile(state, action) {
      const { payload } = action;
      const cloneBill = { ...payload?.data };
      state.billReceiverFile = Object.keys(cloneBill).reduce((acc, i) => {
        const obj = cloneBill[i];
        const newAcc = cloneBill[i].data.map(
          (i) => (i = { ...i, key: geneUniqueKey() })
        );
        const newObj = { ...obj, count: obj.count, data: newAcc };
        acc[`${i}`] = newObj;
        return acc;
      }, {});
      if (!payload?.success) {
        state.errorMessage = payload?.message ?? "";
      }
    },
    startVerifyBill(state) {
      state.billVerifed = {};
    },
    getBillVerifed(state, action) {
      const { payload } = action;
      state.billVerifed = payload?.data ?? {};
    },
    startBillByPhone(state) {
      state.billByPhone = [];
    },
    getBillViaPhone(state, action) {
      const { payload } = action;
      state.billByPhone = payload?.data ?? [];
    },
    startGetTicket(state) {
      state.ticket = {};
    },
    getResponseTicket(state, action) {
      const { payload } = action;
      state.ticket = payload?.data ?? {};
    },
    startGetDsb(state) {
      state.dashboard = {};
    },
    getResponseDsb(state, action) {
      const { payload } = action;
      if (emptyObject(payload)) return;
      state.dashboard = {
        total_up_month: payload?.data["by_month"]?.data,
        by_chart: Object.values(payload?.data?.by_chart ?? {})[0],
        cod_persen: countPersen(
          payload?.data?.by_time?.codInf?.total_cod_income,
          payload?.data?.by_time?.codInf?.total_cod_paid
        ).toFixed(2),
      };
    },
  },
});

export default slice.reducer;

export function getBillExpress(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await BillService.express(
        removeIdentity(pick(params, PARAMS_LISTING))
      );
      dispatch(slice.actions.getBillExpressSuccess(result));
    } catch (error) {
      console.log("res", error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}
export function getTrackingBill(do_code) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBill());
    try {
      const result = await BillService.trackingBill(do_code);
      dispatch(slice.actions.getBillSuccess(result));
    } catch (error) {}
  };
}

export function getStuckImageBill(do_code, type = "") {
  return async (dispatch) => {
    dispatch(slice.actions.startGetStuckImageBill());
    try {
      const result = await BillService.imageIssueBill(do_code, type);
      dispatch(slice.actions.getStuckImageBillSuccess(result));
    } catch (error) {}
  };
}

export function getIssuesBill(do_code, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetIssuse());
    try {
      const result = await BillService.issueDelivery(do_code, params);
      dispatch(slice.actions.getIssueBillSuccess(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}
export function getbillEdit(do_code) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBillCP());
    try {
      const result = await BillService.billByDoCode(do_code);
      dispatch(slice.actions.getBillCP(result));
    } catch (error) {
      console.log("res", error);
    } finally {
      dispatch(slice.actions.finallyBillCp());
    }
  };
}
export function resetBillCp() {
  return (dispatch) => {
    dispatch(slice.actions.startGetBillCP());
  };
}
export function resetBillErp() {
  return (dispatch) => {
    dispatch(slice.actions.startGetBill());
  };
}

export function getBillPending(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBillPending());
    try {
      const result = await BillService.listBillPending(
        removeIdentity(pick(params, PARAMS_LISTING))
      );
      dispatch(slice.actions.getBillPendingSuccesss(result));
    } catch (error) {
      console.log("res", error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getBillFromFile(file, key) {
  return async (dispatch) => {
    dispatch(slice.actions.startSendFile());
    try {
      const result = await BillService.loadBillFromFile(file, key);
      dispatch(slice.actions.getBillFile(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}

export function loadBillRevokeFile(file, key) {
  return async (dispatch) => {
    dispatch(slice.actions.startSendFile());
    try {
      const result = await BillService.loadBillRevokeFile(file, key);
      dispatch(slice.actions.getBillRevokeFile(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}

export function loadBillReceiverFile(file, key) {
  return async (dispatch) => {
    dispatch(slice.actions.startSendFile());
    try {
      const result = await BillService.loadBillReceiverFile(file, key);
      dispatch(slice.actions.getBillReceiverFile(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}

export function resetBillFile() {
  return (dispatch) => {
    dispatch(slice.actions.startSendFile());
  };
}
export function verifyBillImport(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startVerifyBill());
    try {
      const result = await BillService.verifyBillError(params);
      dispatch(slice.actions.getBillVerifed(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}
export function getBillByPhone(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startBillByPhone());
    try {
      const result = await BillService.csFindBillByPhone(
        removeIdentity(params)
      );
      dispatch(slice.actions.getBillViaPhone(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}
export function resetTicket() {
  return (dispatch) => {
    dispatch(slice.actions.startGetTicket());
  };
}
export function getDetailTicket(ticket) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetTicket());
    try {
      const result = await BillService.detailTicket(ticket);
      dispatch(slice.actions.getResponseTicket(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}
export function resetBillCache() {
  return (dispatch) => {
    dispatch(slice.actions.startVerifyBill());
  };
}
export function getReportDsb(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetDsb());
    try {
      const fetP = pick(removeIdentity(params), [
        "time_type",
        "from_date",
        "to_date",
        "status_id",
      ]);
      const result = await BillService.dashboard(fetP);
      dispatch(slice.actions.getResponseDsb(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}
