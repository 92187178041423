import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "services/AuthService";
import { saveUmenu, saveUserAccess, saveUser } from "utils";

const initialState = {
  isLoading: false,
  redirectTo: '',
  authenticate: false,
  token: '',
  message: ''
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLogin(state) {
      state.isLoading = true;
      state.authenticate = false;
      state.redirectTo = '';
      state.token = '';
      state.message = ''
    },
    getUserSuccessFull(state, action) {
      const { payload } = action;
      state.redirectTo = payload.success ? '/' : '/login';
      state.authenticate = payload?.success;
      state.token = payload?.data?.token;
      state.message = payload?.message;
      if(payload.success) {
        saveUmenu(payload);
        saveUserAccess(payload);
        saveUser(payload);
      }
    }
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function login(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLogin());
    try {
      const result = await Auth.login(params);
      dispatch(slice.actions.getUserSuccessFull(result))
    } catch (error) {
      console.log(error);
    } finally {
      console.log("vao");
    }
  };
}
