import moment from "moment";

export const BO_VERSION_BUILDING = `BOv3 - Bản cập nhật: ${window?._env_?.REACT_APP_VERSION}/${window?._env_?.REACT_APP_STAGE}`;

export const PRIMARY = "primary";
export const UI_SIZE = "medium";
export const SUBMIT_TYPE = "submit";
export const MESSAGE_VALIDATE = {
  phone: {
    type: "number",
    placeholder: "Nhập số điện thoại",
    message: "Vui lòng nhập số điện thoại!",
    errorMess: "Số điện thoại chưa đúng định dạng!",
  },
  email: {
    placeholder: "Nhập email",
    message: "Vui lòng nhập email!",
    errorMess: "Email chưa đúng định dạng!",
  },
  password: {
    placeholder: "Nhập password",
    message: "Vui lòng nhập password!",
    atleast: "Mật khẩu tối thiểu tám ký tự",
    character:
      "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt",
  },
  url: {
    placeholder: "Nhập url",
    message: "Vui lòng nhập url!",
    errorMess: "URL chưa đúng định dạng",
  },
};
export const PHONE = "phone";
export const PASSWORD = "password";
export const NEW_PASSWORD = "new-password";
export const OFF = "off";
export const SERVICE = [
  {
    id: 2,
    service_code: "CH",
    service_name: "Tiết kiệm",
    ordered: 1,
    published: 1,
    created_at: "2020-10-07 17:39:42",
    updated_at: "2020-10-07 17:39:42",
  },
  {
    id: 1,
    service_code: "NH",
    service_name: "Nhanh",
    ordered: 2,
    published: 1,
    created_at: "2020-10-06 17:39:07",
    updated_at: "2020-10-07 17:39:42",
  },
];
export const PAYMENT_METHOD = [
  {
    id: 10,
    payment_code: "NGTTN",
    description: "Người Gửi Thanh Toán Ngay",
    is_sender_pay: 1,
    is_pay_immediate: 1,
    created_at: "2019-11-12 18:50:55",
    updated_at: "2019-11-12 18:50:55",
  },
  {
    id: 11,
    payment_code: "NGTTS",
    description: "Người Gửi Thanh Toán Sau",
    is_sender_pay: 1,
    is_pay_immediate: 0,
    created_at: "2019-11-12 18:50:55",
    updated_at: "2019-11-12 18:50:55",
  },
  {
    id: 20,
    payment_code: "NNTTN",
    description: "Người Nhận Thanh Toán Ngay",
    is_sender_pay: 0,
    is_pay_immediate: 1,
    created_at: "2019-11-12 18:50:55",
    updated_at: "2019-11-12 18:50:55",
  },
  {
    id: 30,
    payment_code: "CTCoD",
    description: "Thanh toán cấn trừ COD",
    is_sender_pay: 0,
    is_pay_immediate: 1,
    created_at: "2019-11-12 18:50:55",
    updated_at: "2019-11-12 18:50:55",
  },
];
export const NOTE = [
  { name: "Không cho xem hàng", value: "Không cho xem hàng" },
  {
    name: "Cho xem hàng, không cho thử",
    value: "Cho xem hàng, không cho thử",
  },
  { name: "Cho thử hàng", value: "Cho thử hàng" },
];
export const ItemField = {
  true: {
    name: {
      label: "Họ tên người gửi",
      placeholder: "Nhập tên người gửi",
      name: "s_name",
      rules: [
        {
          required: true,
          message: "Vui lòng nhập tên người gửi",
          whitespace: true,
        },
      ],
    },
    phone: {
      label: "Số điện thoại người gửi",
      placeholder: "Nhập số điện thoại người gửi",
      name: "s_phone",
    },
    address: {
      label: "Địa chỉ",
      placeholder: "Nhập địa chỉ",
      name: "s_address",
      rules: [
        {
          required: true,
          whitespace: true,
          message: "Vui lòng nhập địa chỉ",
        },
      ],
    },

    address_from_file: {
      label: "Địa chỉ nhập từ file",
      placeholder: "Địa chỉ nhập từ file",
      name: "s_full_address_input",
    },
    search_address: {
      label: "Tìm kiếm nhanh địa chỉ tỉnh, quận huyện, xã",
      placeholder: "Tìm kiếm nhanh địa chỉ tỉnh, quận huyện, xã",
      name: "s_search_address",
      auOption: "s_address_options",
      id: "s_search_address",
    },

    province: {
      label: "Tỉnh thành",
      placeholder: "Chọn tỉnh thành",
      name: "s_province_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn tỉnh thành",
        },
      ],
    },
    district: {
      label: "Quận huyện",
      placeholder: "Chọn quận huyện",
      name: "s_district_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn quận huyện",
        },
      ],
    },
    province_name: {
      name: "s_province_name",
    },
    district_name: {
      name: "s_district_name",
    },
    ward_name: {
      name: "s_ward_name",
    },
    ward: {
      label: "Phường xã",
      placeholder: "Chọn phường xã",
      name: "s_ward_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn phường xã",
        },
      ],
    },
    save_address: {
      name: "save_s_address",
      id: "save_s_address",
    },
  },
  false: {
    name: {
      label: "Họ tên người nhận",
      placeholder: "Nhập tên người nhận",
      name: "r_name",
      rules: [
        {
          required: true,
          message: "Vui lòng nhập tên người nhận",
        },
      ],
    },
    phone: {
      label: "Số điện thoại người nhận",
      placeholder: "Nhập số điện thoại người nhận",
      name: "r_phone",
    },
    address: {
      label: "Địa chỉ",
      placeholder: "Nhập địa chỉ",
      name: "r_address",
      rules: [
        {
          required: true,
          message: "Vui lòng nhập địa chỉ",
        },
      ],
    },

    address_from_file: {
      label: "Địa chỉ nhập từ file",
      placeholder: "Địa chỉ nhập từ file",
      name: "r_full_address_input",
    },
    search_address: {
      label: "Tìm kiếm nhanh địa chỉ tỉnh, quận huyện, xã",
      placeholder: "Tìm kiếm nhanh địa chỉ tỉnh, quận huyện, xã",
      name: "r_search_address",
      auOption: "r_address_options",
      id: "r_search_address",
    },
    province_name: {
      name: "r_province_name",
    },
    district_name: {
      name: "r_district_name",
    },
    ward_name: {
      name: "r_ward_name",
    },
    province: {
      label: "Tỉnh thành",
      placeholder: "Chọn tỉnh thành",
      name: "r_province_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn tỉnh thành",
        },
      ],
    },
    district: {
      label: "Quận huyện",
      placeholder: "Chọn quận huyện",
      name: "r_district_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn quận huyện",
        },
      ],
    },
    ward: {
      label: "Phường xã",
      placeholder: "Chọn phường xã",
      name: "r_ward_id",
      rules: [
        {
          required: true,
          message: "Vui lòng chọn phường xã",
        },
      ],
    },
    save_address: {
      name: "save_r_address",
      id: "save_r_address",
    },
  },
};
export const SERVICE_CODE_STANDARD = 2;
export const SERVICE_CODE_EXPRESS = 1;
export const SERVICE_CODE_TIMER = 3;
export const CALCULATEDREF = [
  "s_district_id",
  "s_province_id",
  // "s_ward_id",
  "r_province_id",
  "r_district_id",
  // "r_ward_id",
  "weight",
  "length",
  "height",
  "width",
  "cargo_value",
  "payment_method_id",
  "package_no",
  "cod_amount",
  "service_id",
  "payment_method_id",
  "delivery_apart",
];
export const ERROR_FIELDS_BILL = [
  "length",
  "width",
  "height",
  "cargo_value",
  "cod_amount",
  "weight",
  "s_district_id",
  "s_province_id",
  "r_district_id",
  "r_province_id",
  "package_no",
];
export const REQUIRED_FIELDS = [
  "r_district_id",
  "r_province_id",
  "r_ward_id",
  "s_district_id",
  "s_province_id",
  "s_ward_id",
  "weight",
  "package_no",
  "service_id",
];
export const FIELDS_CHANGE = [
  "s_district_id",
  "s_province_id",
  "s_ward_id",
  "r_district_id",
  "r_province_id",
  "r_ward_id",
  "service_id",
  "payment_method_id",
];
export const PACKAGE_NO_DEFAULT = 1;
export const SERVICE_TYPE = {
  STANDARD: { title: "Giao chuẩn", value: 2, code: "CH" },
  EXPRESS: { title: "Giao nhanh", value: 1, code: "NH" },
  TIMER: { title: "Hẹn giờ", value: 3, code: "HG" },
};
export const PAYMENT_METHOD_ID = {
  10: "NGTTN",
  20: "NNTTN",
  11: "NGTTS",
  30: "CTCoD",
};
export const PAYMENT = { NGTTN: 10, NNTTN: 20, NGTTS: 11, CTCoD: 30 };
export const PACKAGE_NOTE = [
  { name: "Không cho xem hàng", value: 1, label: "show" },
  { name: "Cho xem hàng", value: 2, label: "noshow" },
];
export const QUEUE_NAME = "CreateBillNTXAuth";
export const UTM_SOURCE_CS = "Caresoft";
export const UTM_SOURCE = "BackEnd";
export const USER_ID = "UID";
export const UserPartnerType = "UPartnerType";
export const TRANSPORT = [
  {
    id: 1,
    label: "Lấy hàng bằng xe máy",
    value: 20556,
  },
  { id: 2, label: "Lấy hàng bằng oto", value: 20558 },
];
export const BILL_FIELDS_HIDDEN = [
  "queue_name",
  "utm_source",
  "user_id",
  "authorization",
  "partner_id",
  "discount",
  "coupon_code",
  "transport_by_id",
  "cargo_content_id",
  "postpaidcode",
];
export const CREATE_BILL = [
  "bill_type",
  "authorization",
  "cargo_content",
  "cargo_content_field",
  "cargo_content_id",
  "cargo_value",
  "cod_amount",
  "cod_fee",
  "controlAmt",
  "counting_fee",
  "coupon_code",
  "delivery_fee",
  "dimension_weight",
  "discount",
  "expected_at",
  "height",
  "insurr_fee",
  "is_deduct_cod",
  "length",
  "lifting_fee",
  "main_fee",
  "note",
  "other_fee",
  "package_no",
  "packing_fee",
  "payment_method",
  "payment_method_id",
  "queue_name",
  "r_address",
  "r_district_id",
  "r_name",
  "r_phone",
  "r_province_id",
  "r_ward_id",
  "remote_fee",
  "s_address",
  "s_district_id",
  "s_name",
  "s_phone",
  "s_province_id",
  "s_ward_id",
  "sender_pay_cod",
  "service_id",
  "transfer_cod",
  "transport_by_id",
  "user_id",
  "utm_source",
  "weight",
  "width",
  "ref_code",
  "delivery_apart",
  "base_do_code",
  "revoke_bill",
  "equip_revoke",
  "revoke_info",
  "return_info",
  "is_return_doc",
];
export const CREATE_BILL_CS = [
  "s_name",
  "s_type",
  "s_phone",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "s_address",
  "r_name",
  "r_phone",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
  "r_address",
  "service_id",
  "payment_method_id",
  "cargo_content_id",
  "package_no",
  "weight",
  "cod_amount",
  "transport_by_id",
  "cargo_content",
  "note",
  "utm_source",
  "length",
  "width",
  "height",
  "cargo_value",
  "insurr_fee",
  "main_fee",
];
export const QUEUE_CREATE_SUCCESSFUL = "Tạo đơn thành công";
export const QUEUE_CREATE_UNSUCCESSFUL = "Tạo đơn không thành công";
export const QUEUE_UPDATE_SUCCESSFUL = "Cập nhật đơn thành công";
export const PACKAGE_NO = "package_no";
export const POST_PAID_CODE = "PostPaidCode";
export const ON = 1;
export const LOGIN_PATH = "/login";
export const SESSION_MESSAGE = "Request timeout";
export const BILL_TYPE = [
  {
    name: "Vận đơn theo NTX",
    value: "Y",
  },
  {
    name: "Vận đơn không theo NTX",
    value: "N",
  },
];
export const REVOKE_BILL = ["do_code", "weight", "package_no", "cargo_content"];
export const ACTIVE = "A";
export const STATUS = [
  { name: "Tất cả", value: undefined },
  { name: "Đang xử lý", value: 1 },
  { name: "Hoàn tât", value: 2 },
  { name: "Thất bại", value: 3 },
];
export const AMOUNT = { min: 1_000, max: 20_000_000 };
export const SENDER_ADDRESS = ["s_district_id", "s_province_id", "s_ward_id"];
export const STATUS_LISTING = [
  {
    name: "Tất cả",
    value: "all",
  },
  {
    name: "Đang xử lý",
    value: "0-0",
  },
  {
    name: "Hoàn tất",
    value: "0-1",
  },
  {
    name: "Thất bại",
    value: "0-2",
  },
];
export const CARGO_SETTING_ID = 12;
export const PAYMENT_SETTING_ID = 8;
export const SERVICE_SETTING_ID = 6;
export const SETTING_ID = "setting_id";
export const SOURCE_NTX = "CP_NTX";
export const REVOKE_SETTING_ID = 23;
export const APART_SETTING_ID = 24;
export const UNDELIVERY_SETTING_ID = 27;
export const YES = "Y";
export const DATE_MONTH_YEAR = "DD/MM/YYYY";
export const STATUS_LIST = [
  {
    title: "Đang xử lý",
    keyStatus: "0",
    value: "0-0",
    key: "0-0",
    children: [
      {
        title: "Vận đơn mới",
        value: "0-0-1",
        key: "0-0-1",
      },
      {
        title: "Đã tiếp nhận",
        value: "0-0-2",
        key: "0-0-2",
      },
      {
        title: "Đã lấy hàng",
        value: "0-0-3",
        key: "0-0-3",
      },
      {
        title: "Nhập kho",
        value: "0-0-14",
        key: "0-0-14",
      },
      {
        title: "Đang luân chuyển",
        value: "0-0-10",
        key: "0-0-10",
      },
      {
        title: "Đang giao hàng",
        value: "0-0-4",
        key: "0-0-4",
      },
      {
        title: "Chờ duyệt hoàn",
        value: "0-0-15",
        key: "0-0-15",
      },
      {
        title: "Chờ giao lại",
        value: "0-0-16",
        key: "0-0-16",
      },
      {
        title: "Đang chuyển hoàn",
        value: "0-0-8",
        key: "0-0-8",
      },
    ],
  },
  {
    title: "Hoàn tất",
    keyStatus: "1",
    value: "0-1",
    key: "0-1",
    children: [
      {
        title: "Giao hàng thành công",
        value: "0-1-5",
        key: "0-1-5",
      },
      {
        title: "Chuyển hoàn thành công",
        value: "0-1-7",
        key: "0-1-7",
      },
    ],
  },
  {
    title: "Thất bại",
    value: "0-2",
    key: "0-2",
    keyStatus: "2",
    children: [
      {
        title: "Lỗi lấy hàng",
        value: "0-2-12",
        key: "0-2-12",
      },
      {
        title: "Không lấy được hàng",
        value: "0-2-11",
        key: "0-2-11",
      },
      {
        title: "Delay giao hàng",
        value: "0-2-13",
        key: "0-2-13",
      },
      {
        title: "Hủy",
        value: "0-2-6",
        key: "0-2-6",
      },
      {
        title: "Không giao được",
        value: "0-2-9",
        key: "0-2-9",
      },
    ],
  },
];
export const STATUS_ALL = "all";
export const START_REQUEST = "IS_START";
export const GET_RESPONSE = "IS_DONE";
export const PAGE_DEFAULT = 1;
export const LIMIT_DEFAULT = 20;
export const SENDER_INFO = [
  "sender_name",
  "sender_contact_tel",
  "sender_address",
  "sendWard",
  "sender_district",
  "sender_city",
];
export const RECEIVER_INFO = [
  "receiver_name",
  "receiver_contact_tel",
  "receiver_address",
  "receiveWard",
  "receiver_district",
  "receiver_city",
];
export const DETAIL_BILL = {
  service: { name: "Loại dịch vụ:", type: "content" },
  weight: { name: "Trọng lượng (kg):", type: "content" },
  dimension_weight: { name: "TLQĐ (kg):", type: "number" },
  size: { name: "Kích thước (cm):", type: "content" },
  packageno: { name: "Số kiện: ", type: "number" },
  cargo_value: { name: "Giá trị hàng hóa:", type: "number" },
  goodsType: { name: "Loại hàng hóa:", type: "content" },
  createdBill: { name: "Ngày tạo đơn:", type: "date" },
  date_delivery: { name: "Ngày giao:", type: "date" },
  billstatus_desc: { name: "Trạng thái:", type: "content" },
  utmSource: { name: "Nguồn tạo đơn:", type: "content" },
  description: { name: "Nội dung hàng hóa:", type: "content" },
  note: { name: "Ghi chú:", type: "content" },
};
export const PAY_INFO = {
  totalfee: { name: "Tổng phí:", type: "number" },
  mainfee: { name: "Phí vận chuyển:", type: "number" },
  insurancefee: { name: "Phí bảo hiểm:", type: "number" },
  otherfee: { name: "Phí khác:", type: "number" },
  remotefee: { name: "Phí ngoại thành xa:", type: "number" },
  return_fee: { name: "Phí hoàn(Nếu có):", type: "number" },
  deductAmt: { name: "Giảm trừ:", type: "number" },
  codamt: { name: "Tiền thu hộ:", type: "number" },
  pay_method: { name: "Hình thức thanh toán:", type: "content" },
  isDeductCOD: { name: "Cấn trừ COD:", type: "check" },
  note: { name: "Yêu cầu khi giao:", type: "content" },
  undeliveryCash: {
    name: "Không nhận hàng vẫn thu cước:",
    type: "checkUndeliveryCash",
  },
  is_return_doc: { name: "Thu hồi chứng từ:", type: "checkYN" },
  deliveryApart: {
    name: "Cho phép giao một phần:",
    type: "checkUndeliveryCash",
  },
};
export const REQUEST_DETAIL = {
  status_name: {
    name: "Trạng thái vận đơn",
    type: "content",
    key: "status_name",
  },
  expected_at: {
    name: "Thời gian giao dự kiến",
    type: "content",
    key: "expected_at",
  },
  total_fee: {
    name: "Tổng cước (Vnđ)",
    type: "number",
    key: "total_fee",
  },
  cod_amount: {
    name: "Tiền thu hộ COD (Vnđ)",
    type: "number",
    key: "cod_amount",
  },
};
export const SIZE = ["Length", "Width", "Height"];
export const GOODSTYPE = "goodsType";
export const DEDUCTAMT = "deductAmt";
export const DATE_MONTH_YEAR_HOUR = "DD-MM-YYYY | HH:mm";
export const CARGO_TYPE = 1;
export const PAYMENT_TYPE = 2;
export const REPORT_TYPE = [
  { title: "Sự cố nhận hàng", key: "1" },
  { title: "Sự cố trả hàng", key: "2" },
  { title: "Lịch sử chỉnh sửa", key: "3" },
];

export const UserName = "UName";
export const UserPhone = "UPhone";
export const UserMail = "UMail";
export const UserAvatar = "UAvatar";
export const STATUS_BILL = {
  cancel: 6,
};
export const PRINT_SETTING_ID = 16;
export const RETURN_DOC_ID = 29;
export const PRINT_CONFIG = [
  { id: 2, name: "In A6", value: "A6", size: "Kích thước (mm): 105 x 148" },
  { id: 3, name: "In A7", value: "A7", size: "Kích thước (mm): 74 x 105" },
];
export const PDF_TYPES = ["application/pdf", "application/x-pdf"];
export const PARAMS_LISTING = [
  "limit",
  "page",
  "from_date",
  "to_date",
  "service_id",
  "payment_method_id",
  "q",
  "child_partner",
  "status_list",
  "cod",
  "r_district_id",
  "r_province_id",
  "s_district_id",
  "s_province_id",
  "child_partner",
  "store_id",
];
export const ALL_STATUS = "all";
export const IS_EDIT = [1, 2, 12, 18, 19];
export const IS_DELETE = [1, 2, 11, 12, 18, 19];
export const IS_NOT_COMPLAIN = [5, 6, 7];
export const IS_REDILIERY_APPROVE = [15];
export const IS_PRINT = [5, 6, 7];
export const ERROR_PRINT_MESSAGE = "Không thể in vận đơn đã hoàn thành/đã hủy.";
export const BILL_EDIT_FIELDS = [
  "package_no",
  "cargo_content_id",
  "cargo_content",
  "service_id",
  "payment_method_id",
  "user_id",
  "transport_by_id",
  "s_address",
  "r_address",
  "cod_amount",
  "cargo_value",
  "weight",
  "length",
  "width",
  "height",
  "r_name",
  "r_phone",
  "s_name",
  "s_phone",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "promotion_code",
  "ref_code",
  "is_deduct_cod",
  "note-add",
  "s_info",
  "note",
  "base_do_code",
  "revoke_bill",
  "is_return_doc",
];
export const PARAMS_EXCEL = [
  "from_date",
  "to_date",
  "id_list",
  "status_list",
  "q",
];
export const ArrSelectDate = [
  {
    key: "7_days",
    title: "7 Ngày trước",
    value: 7,
    result: [
      moment().subtract(7, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "14_days",
    title: "14 ngày trước",
    value: 14,
    result: [
      moment().subtract(14, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "30_days",
    title: "30 ngày trước",
    value: 30,
    result: [
      moment().subtract(30, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "this_month",
    title: "Tháng này",
    value: 1,
    result: [moment().startOf("month"), moment().endOf("day")],
  },
  {
    key: "last_month",
    title: "Tháng trước",
    value: 2,
    result: [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  },
  {
    key: "option",
    title: "Tùy chọn",
    value: 999,
    result: [],
  },
];
export const RANGE_VALUE = 7;
export const LATEST_BILL = [
  {
    name: "Đơn gần đây",
    value: "all",
  },
  {
    name: "Đơn sự cố",
    value: "fault",
  },
  {
    name: "Đơn chờ duyệt hoàn",
    value: "pending",
  },
];

export const REASON_Q = [
  {
    value: "NotResponse",
    name: "Không liên hệ được",
  },
  {
    value: "PhoneError",
    name: "Sai số điện thoại",
  },
  {
    value: "RejectReceive",
    name: "Khách từ chối nhận",
  },
];

export const LATEST_BILL_DSB = [
  {
    do_code: "E012302009",
    r_name: "Min test",
    r_phone: "0902150099",
    status_name: "Vận đơn mới",
    service_name: "Tiết kiệm",
    cod_amount: 6000000,
    total_fee: 0,
    created_at: "2021-12-27 15:58:25",
  },
  {
    do_code: "E912301953",
    r_name: "Min test",
    r_phone: "0902150099",
    status_name: "Vận đơn mới",
    service_name: "Tiết kiệm",
    cod_amount: 6000000,
    total_fee: 0,
    created_at: "2021-12-27 15:30:49",
  },
  {
    do_code: "E582301846",
    r_name: "Min test",
    r_phone: "0902150099",
    status_name: "Vận đơn mới",
    service_name: "Tiết kiệm",
    cod_amount: 6000000,
    total_fee: 0,
    created_at: "2021-12-27 15:30:46",
  },
  {
    do_code: "E252301739",
    r_name: "Min test",
    r_phone: "0900150093",
    status_name: "Vận đơn mới",
    service_name: "Tiết kiệm",
    cod_amount: 1100,
    total_fee: 0,
    created_at: "2021-12-27 15:13:43",
  },
  {
    do_code: "E922301632",
    r_name: "bich 1",
    r_phone: "0936542886",
    status_name: "Vận đơn mới",
    service_name: "Tiết kiệm",
    cod_amount: 400000,
    total_fee: 0,
    created_at: "2021-12-27 14:35:23",
  },
];
export const SHORT_CUT_CURRENCY = {
  Bilion: { limit: 999_999_999, k: 1_000_000_000 },
  Milion: { limit: 999_999, k: 1_000_000 },
  Thousand: { limit: 999, k: 1_000 },
};
export const REQUEST_TYPE_SELECT = [
  { name: "Khiếu nại", value: "complain" },
  { name: "Sửa thông tin đơn hàng", value: "edit" },
  { name: "Hủy đơn hàng", value: "cancel" },
];
export const REQUEST_TYPE_SELECT_ONLY_COMPLAIN = [
  { name: "Khiếu nại", value: "complain" },
  { name: "Sửa thông tin đơn hàng", value: "edit", disabled: true },
  { name: "Hủy đơn hàng", value: "cancel", disabled: true },
];
export const REQUEST_TYPE_CHILD = {
  edit: [
    {
      name: "Sửa thông tin nhận hàng",
      value: "editReceiver",
    },
    {
      name: "Sửa tiền COD",
      value: "editCod",
    },
    {
      name: "Sửa trọng lượng hàng hóa",
      value: "editWeight",
    },
    {
      name: "Sửa hình thức thanh toán",
      value: "editPaymentMethod",
    },
    // {
    //   name: "Sửa thông tin hàng hóa",
    //   value: "editCargo",
    // },
  ],
  cancel: [
    { name: "Yêu cầu hủy giao hàng & chuyển hoàn", value: "requireCancel" },
  ],
  cancel_dis: [
    {
      name: "Yêu cầu hủy giao hàng & chuyển hoàn",
      value: "requireCancel",
      disabled: true,
    },
  ],
  complain: [
    { name: "Lấy hàng", value: "pick" },
    { name: "Giao hàng", value: "delivery" },
    { name: "Chuyển hoàn", value: "pending" },
    { name: "Chăm sóc khách hàng", value: "carecustomer" },
    { name: "Tiền COD & Đối soát", value: "cod" },
  ],
  editCOD: [
    {
      name: "Sửa thông tin nhận hàng",
      value: "editReceiver",
    },
    {
      name: "Sửa tiền COD",
      value: "editCod",
      disabled: true,
    },
  ],
  editNoneCOD: [
    {
      name: "Sửa tiền COD",
      value: "editCod",
    },
    {
      name: "Sửa thông tin nhận hàng",
      value: "editReceiver",
      disabled: true,
    },
  ],
  editNone: [
    {
      name: "Sửa thông tin nhận hàng",
      value: "editReceiver",
      disabled: true,
    },
    {
      name: "Sửa tiền COD",
      value: "editCod",
      disabled: true,
    },
  ],
};
export const REQUEST_FIELDS = [
  "r_name",
  "r_phone",
  "r_address",
  "r_ward_id",
  "r_district_id",
  "r_province_id",
  "cod_amount",
  "weight",
  "cargo_content",
  "request_name",
  "request_type",
  "payment_method_id",
  // "payment_method_code",
];
export const REQUEST_FIELD_SEND = [
  "r_name",
  "r_phone",
  "r_address",
  "r_ward_id",
  "r_district_id",
  "cod_amount",
  "cargo_content",
  "request_name",
  "request_type",
  "weight",
  "payment_method_id",
  // "payment_method_code",
];
export const REQUEST_NAME = {
  editReceiver: "Sửa thông tin nhận hàng",
  editCod: "Sửa tiền COD",
  editWeight: "Sửa trọng lượng hàng hóa",
  editPaymentMethod: "Sửa hình thức thanh toán",
  editCargo: "Sửa thông tin hàng hóa",
  requireCancel: "Yêu cầu hủy giao hàng & chuyển hoàn",
};
export const REQUEST_FILTER = [
  { name: "Tất cả", value: "" },
  {
    name: "Sửa thông tin nhận hàng",
    value: "editReceiver",
  },
  {
    name: "Sửa tiền COD",
    value: "editCod",
  },
  {
    name: "Sửa thông tin hàng hóa",
    value: "editCargo",
  },
  {
    name: "Yêu cầu hủy giao hàng & chuyển hoàn",
    value: "requireCancel",
  },
];
export const REQUEST_STATUS_FILTER = [
  {
    type: "All",
    name: "Tất cả",
    value: "",
  },
  {
    type: "Approved",
    name: "Đã duyệt",
    value: "A",
  },
  {
    type: "Reject",
    name: "Từ chối",
    value: "R",
  },
  {
    type: "Pending",
    name: "Đang duyệt",
    value: "P",
  },
  {
    type: "Cancel",
    name: "Đã hủy",
    value: "C",
  },
];
export const PARAMS_REQUEST = [
  "limit",
  "page",
  "from_date",
  "to_date",
  "request_type",
  "status",
  "q",
  "do_code",
  "status_id",
];
export const KEY_GHT1P = "P";
export const PENDING = "P";
export const REJECT = "R";
export const APPROVED = "A";
export const GH1P_TAB = [
  { name: "Chờ duyệt", value: "P" },
  { name: "Đã duyệt", value: "A" },
  { name: "Từ chối", value: "R" },
];
export const MILISECOND = 1_000;
export const START_COUNTING = { type: "IS_START_COUNT" };
export const OUT_TIME_COUNTING = { type: "IS_OUT_TIME" };
export const APPROVAL_TITLE = "Phê duyệt";
export const REJECT_TITLE = "Từ chối";
export const MESSAGE_DELIVERY_APART =
  "Đơn hàng sẽ tự động chuyển về kho không phát được nếu bạn không có hành động gì sau";
export const BANK_FIELDS = [
  "id",
  "bank_id",
  "status",
  "bank_account",
  "bank_number",
  "bank_branch_id",
  "bank_branch_name",
  "partner_id",
  "is_primary",
  "is_bank_cod",
];
export const ADDRESS_TYPE = [
  {
    name: "Địa chỉ gửi",
    value: "send",
  },
  {
    name: "Địa chỉ nhận",
    value: "receive",
  },
];
export const UPDATE_BANK_PARAMS = [
  "id",
  "bank_id",
  "status",
  "bank_account",
  "bank_number",
  "bank_branch_id",
  "bank_branch_name",
  "partner_id",
  "is_primary",
  "is_bank_cod",
];
export const DISABLED = "D";
export const IS_PRIMARY = 1;
export const SHOP_MANAGER = [
  { name: "Danh sách cửa hàng", value: "shop" },
  { name: "Quản lý phân quyền", value: "authorize" },
  { name: "Danh sách nhân viên", value: "employee" },
];
export const SHOP = "shop";
export const SHOP_TAG = {
  A: { color: "#01B2E8", name: "Đang hoạt động" },
  D: { color: "#959595", name: "Ngưng hoạt động" },
};
export const ADDRESS_STORE = [
  "address",
  "province_id",
  "province_name",
  "district_id",
  "district_name",
  "ward_id",
  "ward_name",
];
export const GROUP_NAME = "group_name";
export const PERMISSION_FIELDS = ["phone", "email", "partner_name"];
export const EMPLOYEE_FIELDS_SUBMIT = [
  "phone",
  "partner_name",
  "email",
  "permission_id",
  "permission_name",
  "shop_id",
  "shop_name",
  "province_id",
  "province_name",
  "district_id",
  "district_name",
  "ward_id",
  "ward_name",
  "address",
  "partner_address_id",
  "permission_value",
];
export const EMPLOYEE_FIELDS = [
  "shop_name",
  "permission_name",
  "province_id",
  "province_name",
  "district_id",
  "district_name",
  "ward_id",
  "ward_name",
  "address",
  "partner_address_id",
  "permission_value",
];
export const TRUTHY = 1;
export const FALSY = 0;
export const VIET_NAM = 84;
export const AUTHORIZE = "authorize";
export const BILL_EXCEL = [
  {
    name: "Đơn hợp lệ",
    value: "success",
  },
  {
    name: "Đơn không hợp lệ",
    value: "error",
  },
  {
    name: "Đơn không hỗ trợ",
    value: "warning",
  },
];

export const BILL_FILE = [
  {
    name: "Đơn hợp lệ",
    value: "success",
  },
  {
    name: "Đơn không hợp lệ",
    value: "error",
  },
];
export const TYPE_EXCEL = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/excel",
  "application/x-msexcel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
];
export const PERMISSIONS = {
  ADD_EMPLOYEE: "add_employee",
  CANCEL_BILL: "cancel_bill",
  CHANGE_PERMISSION: "change_permission",
  CREATE_BILL: "create_bill",
  DETAIL_BILL: "detail_bill",
  EDIT_COD: "edit_cod",
  EDIT_GENERAL_BILL: "edit_general_bill",
  PRINT_BILL: "print_bill",
  REQUEST_RETURN: "request_return",
  UPDATE_BILL: "update_bill",
  REQUEST_REDELIVERY: "request_redelivery",
};
export const PERMISSION_NOT_ALLOW =
  "Tài khoản không có quyền thực hiện tính năng này";
export const PAGING_DEFAULT = {
  total: 1,
  current_page: 1,
  from: 1,
  to: 10,
  per_page: 10,
  last_page: 1,
};
export const PERMISSION_ROUTE_DENY =
  "Xin lỗi, tài khoản hiện không được phân quyền để xem chi tiết vận đơn";
export const VERIFY_BILL = [
  "s_name",
  "s_phone",
  "s_address",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "s_full_address",
  "s_temp_address",
  "r_name",
  "r_phone",
  "r_address",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
  "r_full_address",
  "r_temp_address",
  "service_id",
  "service_name",
  "payment_method_id",
  "payment_method_name",
  "cargo_content",
  "cargo_content_id",
  "weight",
  "package_no",
  "cod_amount",
  "note",
  "ref_code",
  "bill_file_id",
  "utm_source",
  "transport_by_id",
  "is_deduct_cod",
  "delivery_apart",
  "cargo_value",
  "supportSender",
  "key",
];
export const VERIFY_BILL_ERROR = [
  "s_name",
  "s_phone",
  "s_address",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "s_full_address",
  "s_temp_address",
  "r_name",
  "r_phone",
  "r_address",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
  "r_full_address",
  "r_temp_address",
  "service_id",
  "service_name",
  "payment_method_id",
  "payment_method_name",
  "cargo_content",
  "cargo_content_id",
  "weight",
  "package_no",
  "cod_amount",
  "note",
  "ref_code",
  "bill_file_id",
  "utm_source",
  "transport_by_id",
  "is_deduct_cod",
  "delivery_apart",
  "cargo_value",
  "supportSender",
  "key",
];
export const EXCEL_UTM_SOURCE = "BackEnd_FileExcel";
export const ERROR = "[ERROR]";
export const ADDRESS_NAME = [
  "s_ward_name",
  "r_ward_name",
  "s_district_name",
  "r_district_name",
  "s_province_name",
  "r_province_name",
];
export const SEND_ADDRESS_ID = [
  "s_address",
  "s_province_id",
  "s_ward_id",
  "s_district_id",
];
export const RE_ADDRESS_ID = [
  "r_address",
  "r_province_id",
  "r_ward_id",
  "r_district_id",
];
export const BILL_IMPORT_ERRORS = [
  "service_id",
  "package_no",
  "s_phone",
  "r_phone",
  "payment_method_id",
  "weight",
  "s_address",
  "r_address",
  "s_ward_id",
  "r_ward_id",
  "s_district_id",
  "r_district_id",
  "s_province_id",
  "r_province_id",
  // "cargo_value",
];
export const BILL_IMPORT_MESSAGE = "Trường đang có giá trị không hợp lệ";
export const ADDRESS_SEND_TYPE = 1;
export const SUCCESS_TAB = "success";
export const ERROR_TAB = "error";
export const NTX_FILE_QUEUE = "CreateBillNTXFromFile";
export const STATUS_PREVENT_REQUEST = [1, 2, 5, 6, 7, 8, 11, 12];
export const STATUS_ALL_CONTENT = "Trạng thái vận đơn: Tất cả";
export const PROMOTION_CODE = "promotion_code";
export const LINK_IMG_PINK = [
  "uploads/bills/2021/12/13/RD/E091719767/1639394529536_RD_E091719767.jpg",
  "uploads/bills/2021/12/13/RD/E091719767/1639394529536_RD_E091719767.jpg",
  // "uploads/bills/2021/12/13/RD/E091719767/1639394529536_RD_E091719767.jpg",
  // "uploads/bills/2021/12/13/RD/E091719767/1639394529536_RD_E091719767.jpg",
  // "uploads/bills/2021/12/13/RD/E091719767/1639394529536_RD_E091719767.jpg",
];
export const IMAGE_PINK = "linkimgpinkbill";
export const IMPORT_VER_1 = 1;
export const IMPORT_VER_2 = 2;
export const WARNING_NOTI = `Đề nghị Quý khách hàng lấy hóa đơn dịch vụ trước ngày mùng 5 của tháng tiếp theo. Sau thời hạn trên. Nhất Tín Express sẽ khai với cơ quan thuế dưới hình thức “Người mua không lấy hóa đơn” và từ chối xuất hóa đơn cho khách `;
export const MARQUEE_SPEED = 120;
export const RESET_FIELDS_FORM = [
  "s_name",
  "s_phone",
  "s_address",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "s_ward_name",
  "r_ward_name",
  "s_district_name",
  "r_district_name",
  "s_province_name",
  "r_province_name",
  "r_name",
  "r_phone",
  "r_address",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
  "weight",
  "cod_amount",
  "cargo_value",
  "ref_code",
  "discount",
  "coupon_code",
  "length",
  "width",
  "height",
];
export const CS_TABS = [
  { name: "Tạo đơn", value: "create" },
  { name: "Tra cứu", value: "search" },
  { name: "Tra cứu thông tin khách hàng", value: "cs" },
  window?._env_?.REACT_APP_STAGE === "development" && {
    name: "Khảo sát",
    value: "ks",
  },
];
export const CRM_INFO = {
  partner_name: "Họ và tên",
  partner_phone: "Số điện thoại",
  email_address: "Email",
  account_name: "Tên công ty",
  address: "Địa chỉ",
  province_name: "Tỉnh",
  utm_source: "Mã chương trình",
  utm_source_user: "Mã nhân viên giới thiệu",
  assigned_user_name: "Giao cho nhân viên",
  description: "Nội dung",
};
export const TICKET_DIFF_CONTENT = {
  payment_method_id: { name: "Hình thức thanh toán" },
  weight: { name: "Trọng lượng", format: true },
  "package-no": { name: "Số kiện" },
  cargo_content: { name: "Nội dung hàng hóa" },
  cargo_content_name: { name: "Nội dung hàng hóa" },
  cargo_value: { name: "Giá trị hàng hóa" },
  cod_amount: { name: "Tiền thu hộ (COD)", format: true },
  r_phone: { name: "Số điện thoại nhận" },
  r_name: { name: "Người nhận" },
  r_district_name: { name: "Quận/Huyện nhận" },
  r_ward_name: { name: "Phường/xã nhận" },
  r_address: { name: "Địa chỉ nhận" },
};
export const REQUIRE_CANCEL = "requireCancel";
export const PRINTED = "Đã in vận đơn";
export const PRINT_YET = "Chưa in vận đơn";
export const ISSUES_BILLS = ["0-0-11", "0-0-12", "0-0-13", "0-0-6", "0-0-9"];
export const COMPLAIN_CONTEXT = [
  "Không đến lấy hàng",
  "Láy hàng chậm",
  "Lấy không hết hàng",
  "Cập nhật sai lý do Delay",
  "Cập nhật chạm/Sai trạng thái ĐH",
  "Cập nhật sai cân/Kích thước",
  "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
  "Lý do khác",
];
export const NTX_COMPLAIN_CONTEXT = {
  pick: [
    {
      name: "Không đến lấy hàng",
      value: 1,
    },
    {
      name: "Lấy hàng chậm",
      value: 2,
    },
    {
      name: "Lấy không hết hàng",
      value: 3,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 4,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 5,
    },
    {
      name: "Cập nhật sai cân/Kích thước",
      value: 6,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 7,
    },
    {
      name: "Lý do khác",
      value: 8,
    },
  ],
  delivery: [
    {
      name: "Hàng hư hỏng, vỡ",
      value: 9,
    },
    {
      name: "Hàng thất lạc, giao thiếu, giao nhầm",
      value: 10,
    },
    {
      name: "Hàng giao chậm",
      value: 11,
    },
    {
      name: "Sai hình thức trung chuyển",
      value: 12,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 13,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 14,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 15,
    },
    {
      name: "Lý do khác",
      value: 16,
    },
  ],
  pending: [
    {
      name: "Shop chưa nhận được hàng",
      value: 17,
    },
    {
      name: "Hàng hỏng vỡ",
      value: 18,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 19,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 20,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 21,
    },
  ],
  carecustomer: [
    {
      name: "CSKH thái độ kém",
      value: 22,
    },
    {
      name: "CSKH hỗ trợ/Xử lý chưa hiệu quả",
      value: 23,
    },
  ],
  cod: [
    {
      name: "Chưa nhận được tiền chuyển khoản",
      value: 24,
    },
    {
      name: "Đối soát thiếu tiền",
      value: 25,
    },
    {
      name: "Thu sai tiền thu hộ",
      value: 26,
    },
  ],
};
export const REQUEST_COMPLAIN = [
  "email",
  "message",
  "phone",
  "username",
  "subject",
];
export const BILL_TYPE_TH = [
  { name: "Tạo đơn thường", value: "NO" },
  { name: "Tạo đơn thu hồi", value: "TH" },
];

export const STATUS_BANNER = [
  {
    value: "A",
    name: "Đang hoạt động",
  },
  {
    value: "D",
    name: "Ngưng hoạt động",
  },
];
export const BANNER_TYPE = [
  {
    value: "app",
    name: "APP",
  },
  {
    value: "be_slide",
    name: "BO",
  },
  {
    value: "fe_slide",
    name: "WEB PC",
  },
  {
    value: "fe_slide_mb",
    name: "WEB MB",
  },
  {
    value: "SEO",
    name: "SEO",
  },
];

export const LANG_CODE = [
  { value: "vi", name: "Tiếng Việt" },
  { value: "en", name: "Tiếng Anh" },
];
export const NORMAL = "NO"; // Create bill usual
export const REVOKE3PL = "TH"; // Create bill usual
export const PENDING_TYPE = "pending";
export const SEND_ADDRESS = 1;
export const RECEIVE_ADDRESS = 2;
