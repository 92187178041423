import { Request } from "./https";

export const Address = {
  provinces: (params) => {
    const newRequest = new Request("get", `province`, params);
    return newRequest.sendXHR();
  },
  district: (params) => {
    const newRequest = new Request("get", `district`, params);
    return newRequest.sendXHR();
  },
  districtById: (id) => {
    const newRequest = new Request("get", `district/${id}`, {});
    return newRequest.sendXHR();
  },
  provinceById: (id) => {
    const newRequest = new Request("get", `province/${id}`, {});
    return newRequest.sendXHR();
  },
  ward: (params) => {
    const newRequest = new Request("get", `ward`, params);
    return newRequest.sendXHR();
  },
  wardById: (id) => {
    const newRequest = new Request("get", `ward/${id}`);
    return newRequest.sendXHR();
  },
  partnerAddress: params => {
    const newRequest = new Request('get', `partner-address`, params);
    return newRequest.sendXHR();
  },
  addWardInfo: (params) => {
    const newRequest = new Request("post", `add-wardInfo`, params);
    return newRequest.sendXHR();
  },
  updateWardInfo: (params) => {
    const newRequest = new Request("put", `update-wardInfo`, params);
    return newRequest.sendXHR();
  },
  partnerAddressById: (id) => {
    const newRequest = new Request('get', `partner-address/${id}`);
    return newRequest.sendXHR();
  },
};
