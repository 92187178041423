import { createSlice } from "@reduxjs/toolkit";
import { Statistics } from "services/Statistics";

const initialState = {
  isLoading: false,
  statistics: [],
  codPaymentList: [],
  codPaymentDetail: [],
  billFault: [],
  paging: {}
};
const slice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    startGetting(state) {
      state.isLoading = true;
      state.paging = {};
    },
    getListBillFeeStatisticSuccess(state, action) {
      const { payload } = action;
      state.statistics = payload?.data ?? [];
      state.paging = payload?.data.paging ?? {}
    },
    getCodPaymentListSuccess(state, action) {
      const { payload } = action;
      state.codPaymentList = payload?.data ?? [];
      state.paging = payload?.data.paging ?? {}
    },
    getCodPaymentDetailSuccess(state, action) {
      const { payload } = action;
      state.codPaymentDetail = payload?.data ?? [];
    },
    getBillFaultlSuccess(state, action) {
      const { payload } = action;
      state.billFault = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    doneGetting(state) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export function getListBillFeeStatistic(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Statistics.getListBillFeeStatistic(params);
      dispatch(slice.actions.getListBillFeeStatisticSuccess(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getCodPaymentListSuccess(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Statistics.getCodPaymentList(params);
      dispatch(slice.actions.getCodPaymentListSuccess(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getCodPaymentDetail(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Statistics.getCodPaymentDetail(params);
      dispatch(slice.actions.getCodPaymentDetailSuccess(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getBillFault(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Statistics.billIssue(params);
      dispatch(slice.actions.getBillFaultlSuccess(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}