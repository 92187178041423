import { Request } from "./https";

export const BankService = {
  listing: (params) => {
    const newRequest = new Request("get", `partner-bank`, params);
    return newRequest.sendXHR();
  },
  banking: (params) => {
    const newRequest = new Request("get", `banking?limit=${-999}`, params);
    return newRequest.sendXHR();
  },
  bank: (id, params) => {
    const newRequest = new Request("get", `partner-bank/${id}`, params);
    return newRequest.sendXHR();
  },
  updateBank: (id, params) => {
    const newRequest = new Request("put", `partner-bank/${id}`, params);
    return newRequest.sendXHR();
  },
  addBank: (params) => {
    const newRequest = new Request("post", `partner-bank`, params);
    return newRequest.sendXHR();
  },
};
