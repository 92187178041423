import { createSlice } from "@reduxjs/toolkit";
import { BillFile } from "services/BillFile";

const initialState = {
  isLoading: false,
  bilFile: [],
  paging: [],
  billError: [],
  pagingBillError: [],
};

const slice = createSlice({
  name: "billFile",
  initialState,
  reducers: {
    startGetBillFile(state) {
      state.isLoading = true;
      state.bilFile = [];
      state.paging = {};
    },
    resultBillFile(state, action) {
      const { payload } = action;
      state.bilFile = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    startGetBillError(state) {
      state.billError = [];
    },
    resultBillError(state, action) {
      const { payload } = action;
      state.billError = payload?.data ?? [];
      state.pagingBillError = payload?.paging ?? {};
    },
    doneGetting(state) {
      state.isLoading = false;
    },
  },
});
export default slice.reducer;

export function getBillFile(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBillFile());
    try {
      const result = await BillFile.getBillFile(params);
      dispatch(slice.actions.resultBillFile(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getBillFileError(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBillError());
    try {
      const result = await BillFile.getBillError(params);
      dispatch(slice.actions.resultBillError(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}
