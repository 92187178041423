import { Request } from "./https";
import { postMethod } from "utils";

export const Banner = {
  getBanner: (params) => {
    const newRequest = new Request("get", `/banner`, params);
    return newRequest.sendXHR();
  },
  createBanner: (params) => {
    const newRequest = new Request(`post`, `/banner`, postMethod(params));
    return newRequest.sendXHR();
  },

  updateBanner: (params, id) => {
    const url = `/banner/${id}`;
    const newRequest = new Request(`put`, url, postMethod(params));
    return newRequest.sendXHR();
  },
};
