import moment from "moment";
import pickBy from "lodash/pickBy";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import { TOKEN } from "constants/AppConst";
import { CDN_URL } from "config/Config";
import {
  USER_ID,
  POST_PAID_CODE,
  LOGIN_PATH,
  DATE_MONTH_YEAR,
  UserName,
  UserPhone,
  UserMail,
  UserAvatar,
  IS_PRINT,
  RANGE_VALUE,
  SHORT_CUT_CURRENCY,
  MILISECOND,
  TRUTHY,
  FALSY,
  UserPartnerType,
} from "constants/variables";
import ShortUniqueId from "short-unique-id";
import md5 from "md5";

export function validatePassword(password) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    password
  );
}
export function validateEmail(inputText) {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/; //eslint-disable-line
  if (inputText.match(mailformat)) {
    return true;
  }
  return false;
}
export function validateMobile(phone) {
  let flag = false;
  phone = phone.replace("(+84)", "0"); //eslint-disable-line
  phone = phone.replace("+84", "0"); //eslint-disable-line
  phone = phone.replace("0084", "0"); //eslint-disable-line
  phone = phone.replace(/ /g, ""); //eslint-disable-line
  if (phone !== "") {
    let vnf_regex =
      /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/g; //eslint-disable-line
    if (vnf_regex.test(phone) === true && phone.length === 10) {
      flag = true;
    }
  }
  return flag;
}
export const postMethod = (input = {}) => {
  return omitBy(input, isNil); //eslint-disable-line
};
export const currencyFormat = (num) => {
  if (num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else return;
};
export const formatRangePicker = (values) => {
  if (values) {
    let a = values.split(",");
    a[0] = moment(a[0]).format("DD/MM/YYYY");
    a[1] = moment(a[1]).format("DD/MM/YYYY");
    return a[0] + " - " + a[1];
  } else return;
};
export const parseCatchError = (error) => {
  const defaultMess = "Lỗi! Vui lòng thử lại";
  return JSON.parse(JSON.stringify(error?.data?.message)) ?? defaultMess;
};

// export excel
export const exportExcel = (file, title = "Report_", endpoint = ".xlsx") => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  link.href = url;
  link.setAttribute("download", title + year + month + date + endpoint); //or any other extension
  document.body.appendChild(link);
  link.click();
};
function removeSign(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o"); //ò đầu tiên là ký tự đặc biệt
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}
export const FormatNumber = (number) => {
  return new Intl.NumberFormat("vi-VN").format(number);
};
export const isVietnamesePhoneNumber = (number) => {
  return /((03|05|07|08|09)+([0-9]{8}))\b|((02)+([0-9]{9}))\b|(^(19)+([0-9]{6,8}))\b|(^(18)+([0-9]){6,8})\b/.test(
    number
  );
};
export const chimupstring = (string) => {
  return removeSign(string).split(" ").join("");
};
export const removeUnicode = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
    .replace(/[^a-zA-Z0-9 ]/g, "");
};
export const getQueryParams = (url) =>
  url.match(/([^?=&]+)(=([^&]*))/g).reduce((total, crr) => {
    const [key, value] = crr.split("=");
    total[key] = value;
    return total;
  }, {});
export const getAddressString = (a, w, d, p) => {
  let ret = "";
  ret += `${a ? a + ", " : ""}`;
  ret += `${w ? w + ", " : ""}`;
  ret += `${d ? d + ", " : ""}`;
  ret += `${p ? p + ", " : ""}`;
  return ret.replace(/,$/, "").replace(/, $/, "");
};
export const removeIdentity = (obj) => {
  if (!obj) return {};
  const arrCheck = [undefined, null, ""];
  let result = pickBy(obj, (v) => !arrCheck.includes(v));

  return result;
};
function ButtonTab(type, value) {
  this.type = type;
  this.value = value;
  this.renderButton = function () {
    return this.type === value
      ? "btn-change-status-active"
      : "btn-change-status";
  };
}

export const buttonCustomTab = (type, value) => {
  const newButton = new ButtonTab(type, value);
  return newButton.renderButton();
};
if (!Array.prototype.objFromArray) {
  Array.prototype.objFromArray = function (prop) {
    const key = prop || "id";
    return this.reduce((acc, i) => {
      acc[i[key]] = i;
      return acc;
    }, {});
  };
}
if (!Array.prototype.customFindIdx) {
  Array.prototype.customFindIdx = function (prop, value) {
    const idx = this.findIndex((i) => i[prop] === value);
    return idx;
  };
}
if (!Array.prototype.findAndRemove) {
  Array.prototype.findAndRemove = function (value, key = null) {
    const index = this.findIndex((obj) =>
      !!obj[key] ? obj[key] === value : obj === value
    );
    return index >= 0
      ? [...this.slice(0, index), ...this.slice(index + 1)]
      : this;
  };
}
export const timeout = (prom, time) =>
  Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))]);

export const checkArray = (input) => {
  return Array.isArray(input);
};

export const emptyObject = (obj) => {
  return !Boolean(Object.values(obj || {}).length);
};
export const saveUserAccess = (response) => {
  localStorage.setItem(
    "PShop",
    response.data.partner_info.parent_partner_id === 0 ? 1 : 0
  );
  localStorage.setItem(
    "ShopID",
    response.data.partner_info.parent_partner_id === 0
      ? 0
      : response.data.partner_info.shopList
  );
  localStorage.setItem(
    "PostPaidCode",
    response.data.partner_info.post_paid_code
  );
  localStorage.setItem(
    "UStore",
    JSON.stringify(response?.data?.partner_info?.store ?? "")
  );
};

export const saveUmenu = (response) => {
  localStorage.setItem("UMenu", response.data.partner_menu);
  localStorage.setItem("SecondMenu", response?.data?.sub_menu);
};

export const saveUser = (response) => {
  localStorage.setItem(TOKEN, response?.data?.token);
  localStorage.setItem("UID", response.data.partner_info.id);
  localStorage.setItem("UName", response.data.partner_info.partner_name);
  localStorage.setItem("UPhone", response.data.partner_info.phone);
  localStorage.setItem("UMail", response.data.partner_info.email);
  localStorage.setItem("UAvatar", CDN_URL + response.data.partner_info.avatar);
  localStorage.setItem("UPartnerType", response.data.partner_info.partner_type);
  localStorage.setItem("uuid", response.data.uuid);
};
export const geneUniqueKey = () => {
  const uid = new ShortUniqueId();
  return uid();
};
export const revertCurrency = (currency = "") => {
  if ([null, undefined].includes(currency)) return;
  if (typeof currency === "number") return currency;
  return Number(currency.replace(/[^0-9.-]+/g, ""));
};
export const getPermissions = () => {
  return localStorage.getItem("UStore")
    ? JSON.parse(localStorage.getItem("UStore"))
    : null;
};
export const User = {
  id: function () {
    return localStorage.getItem(USER_ID) ? localStorage.getItem(USER_ID) : "";
  },
  token: function () {
    return localStorage.getItem(TOKEN) ? localStorage.getItem(TOKEN) : "";
  },
  postpaidCode: function () {
    return localStorage.getItem(POST_PAID_CODE)
      ? localStorage.getItem(POST_PAID_CODE)
      : "";
  },
  name: function () {
    return localStorage.getItem(UserName) ? localStorage.getItem(UserName) : "";
  },
  phone: function () {
    return localStorage.getItem(UserPhone)
      ? localStorage.getItem(UserPhone)
      : "";
  },
  email: function () {
    return localStorage.getItem(UserMail) ? localStorage.getItem(UserMail) : "";
  },
  avatar: function () {
    return localStorage.getItem(UserAvatar)
      ? localStorage.getItem(UserAvatar)
      : "";
  },
  permissions: function () {
    return getPermissions();
  },
  partnerType: function () {
    return localStorage.getItem(UserPartnerType)
      ? localStorage.getItem(UserPartnerType)
      : "";
  },
  uuid: function () {
    return localStorage.getItem("uuid") ? localStorage.getItem("uuid") : "";
  },
};
export const returnLoginPath = () => {
  let urls = window.location.href;
  let arr = urls.split("/");
  arr.splice(3, arr.length - 3);
  let newUrl = arr.join("/");
  newUrl = `${newUrl}${LOGIN_PATH}`;
  localStorage.removeItem(TOKEN);
  window.location.href = newUrl;
};
export const addressGeneString = (
  name = "",
  phone = "",
  address = "",
  ward = "",
  district = "",
  province = ""
) => {
  return `${name} - SĐT: ${phone} - ${address}, ${ward}, ${district}, ${province}`;
};

export const showDate = (v, formatString = DATE_MONTH_YEAR) => {
  if (v === "0000-00-00 00:00:00") return "-";
  return v ? moment(v).format(formatString) : "";
};
export const RANGE_PICKER_DEFAULT = [
  moment().subtract(RANGE_VALUE, "days").startOf("day"),
  moment().endOf("day"),
];
export const range_default = () => {
  return {
    from_date: moment(RANGE_PICKER_DEFAULT[0]).unix(),
    to_date: moment(RANGE_PICKER_DEFAULT[1]).unix(),
  };
};
export const range_default_DDMMYYYY = () => {
  return {
    from_date: moment(RANGE_PICKER_DEFAULT[0]).format("DD/MM/YYYY"),
    to_date: moment(RANGE_PICKER_DEFAULT[1]).format("DD/MM/YYYY"),
  };
};
export const exportPdf = (file) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank"); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const handleCheckedValues = (arr) => {
  return (arr ?? []).reduce((acc, i) => {
    const a = i.split("-");
    const key = a.at(1);
    const value = a.at(2);
    const obj = {};
    if (value && key) {
      obj.id = key;
      obj.value = value;
      acc.push(obj);
    }
    return acc;
  }, []);
};
export const trimString = (v) => (v ?? "").trim();

export const getRangeDate = (arr = []) => {
  if (!Boolean(arr)) return range_default();
  return arr.reduce((acc, i, index) => {
    const key = index === 0 ? "from_date" : "to_date";
    acc[`${key}`] = moment(i).unix();
    return acc;
  }, {});
};
export const validatePrint = (arr = []) => {
  return (arr ?? []).reduce((acc, i) => {
    return !IS_PRINT.includes(i.status_id) ? acc : [...acc, i];
  }, []);
};
export const kFormatter = (num) => {
  if ([undefined, null, ""].includes(num)) {
    return;
  }
  if (Math.abs(num) > SHORT_CUT_CURRENCY.Bilion.limit) {
    return (
      (
        Math.sign(num) *
          (Math.abs(num) / SHORT_CUT_CURRENCY.Bilion.k).toFixed(6) +
        ""
      ).substring(5, "") + "B"
    );
  }
  if (Math.abs(num) > SHORT_CUT_CURRENCY.Milion.limit) {
    return (
      Math.sign(num) *
        (Math.abs(num) / SHORT_CUT_CURRENCY.Milion.k).toFixed(2) +
      "M"
    );
  }
  if (Math.abs(num) > SHORT_CUT_CURRENCY.Thousand.limit) {
    return (
      Math.sign(num) *
        (Math.abs(num) / SHORT_CUT_CURRENCY.Thousand.k).toFixed(1) +
      "K"
    );
  }
  return Math.sign(num) * Math.abs(num);
};
export const keyPageTicket = () => {
  return "cancel_tiket_" + moment().unix();
};
export const convertToCountDown = (v) => {
  return Date.now() + (v ?? 0) * MILISECOND;
};
export const convertBoolean = (obj) => {
  Object.keys(obj).map((i) => {
    obj[i] = Boolean(obj[i]) ? TRUTHY : FALSY;
  });
};
export const revertBoolean = (obj) => {
  Object.keys(obj).map((i) => {
    obj[i] = Boolean(obj[i]);
  });
};
export const securityString = (string) => {
  const newString = string.slice(-3);
  return newString.padStart(11, "*");
};
export const emptyArr = (arr) => {
  if (!Array.isArray(arr)) return true;
  return !arr.length;
};
export const geneImageUrl = (string) => `${CDN_URL}${string}`;

export function TextAbstract(text, length) {
  if (text == null) {
    return "";
  }
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);
  const last = text.lastIndexOf(" ");
  text = text.substring(0, last);
  return <span>{text + "..."}</span>;
}

export const generateHeaderTokenEAPI = (params) => {
  const token_generate = "apia7jauzHLvDY7DpCb";
  const token = md5(token_generate + "" + JSON.stringify(params));
  return token;
};
