import moment from "moment";
export const FORMAT_DATE = "YYYY-MM-DD";
export const FORMAT_DATE_TIME = "YYYY-MM-DD HH-MM-SS";
export const FILTER_TOP = 80;
export const MODAL_WIDTH = 1024;
export const OS_APP_ID = "8727f910-944c-4ae9-ab60-c9a473ba5720";

// Object
export const ranges = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
};

export const TOKEN = "NTXAuthenticate";

export const SPECIAL_CHARACTER_REGX = /[!@#$%^&*(),.?":{}|<>]/g;
export const ENGLIST_REGX = /^[A-Za-z0-9_.]+$/g;
export const TIME_OUT = 60_000;
export const SESSION_MESSAGE = "Request timeout";
export const LOGIN_PATH = "/login";

export const PAYMENT_METHOD_ID_CTCOD = 30;
