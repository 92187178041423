import { Request } from "./https";
import md5 from "md5";

export const Auth = {
  login: (params) => {
    const newRequest = new Request("post", `sign-inPhone`, params);
    return newRequest.sendXHR();
  },
  resetPasswordPhone: (params) => {
    params.uuid_v2 = md5(params['phone'] + "NTE@@001");
    const newRequest = new Request("post", `reset-passwordPhone`, params);
    return newRequest.sendXHR();
  },
  confirmResetPasswordPhone: (params) => {
    const newRequest = new Request("post", `confirm-resetPasswordPhone`, params);
    return newRequest.sendXHR();
  },
  updatePartnerPhone: (params) => {
    const newRequest = new Request("post", `update-partnerPhone`, params);
    return newRequest.sendXHR();
  },
  signUpPhone: (params) => {
    params.uuid_v2 = md5(params['phone'] + "NTE@@001");
    const newRequest = new Request("post", `sign-upPhone`, params);
    return newRequest.sendXHR();
  },
  confirmSignUpPhone: (params) => {
    const newRequest = new Request("post", `confirm-SignUpPhone`, params);
    return newRequest.sendXHR();
  },
};
