import { createSlice } from "@reduxjs/toolkit";
import { BankService } from "services/BankService";
import { removeIdentity } from "utils";

const initialState = {
  banks: [],
  partner_bank: {},
};

const slice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    startGetBanks(state) {
      state.banks = [];
    },
    startGetBank(state) {
      state.partner_bank = {};
    },
    getAllBankSuccess(state, action) {
      const { payload } = action;
      state.banks = payload?.data ?? [];
    },
    getBankSuccess(state, action) {
      const { payload } = action;
      state.partner_bank = payload?.data ?? {};
    },
  },
});

export default slice.reducer;

export function getAllBank() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBanks());
    try {
      const result = await BankService.banking();
      dispatch(slice.actions.getAllBankSuccess(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}

export function getBank(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBank());
    try {
      const result = await BankService.bank(id, removeIdentity(params));
      dispatch(slice.actions.getBankSuccess(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}
export function resetPartnerBank() {
  return (dispatch) => {
    dispatch(slice.actions.startGetBank());
  };
}
