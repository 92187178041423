import { createSlice } from "@reduxjs/toolkit";
import { Address } from "services/Address";
import { emptyArr, removeIdentity } from "utils";
import { SEND_ADDRESS } from "constants/variables";

const initialState = {
  address: [],
  partner_address: [],
  provinces: [],
  send_main_address: {},
  send_main_address2: {},
  receive_main_address: {},
  receive_main_address2: {},
  s_address: [],
  r_address: [],
  s_address2: [],
  r_address2: [],
};
const mainAddress = (i) => i.is_main_address === SEND_ADDRESS;
const pickMainAddress = (data) => {
  const filteredData = data.filter(mainAddress);
  if (emptyArr(filteredData)) return {};
  return filteredData.at(0);
};
const slice = createSlice({
  name: "address",
  initialState,
  reducers: {
    startGetting(state) {
      state.address = [];
    },
    getAddressSuccess(state, action) {
      const { payload } = action;
      state.address = payload?.data ?? [];
    },
    startGetPartnerAddress(state) {
      state.partner_address = [];
    },
    getPartnerAddressSuccess(state, action) {
      const { payload } = action;
      state.partner_address = payload?.data ?? [];
    },
    updateProvinceData(state, action) {
      const { payload } = action;
      state.provinces = payload ?? [];
    },
    startGetSender(state) {
      state.s_address = [];
      state.send_main_address = {};
    },
    startGetSender2(state) {
      state.s_address2 = [];
      state.send_main_address2 = {};
    },
    getSenderResponse(state, action) {
      const { payload } = action;
      state.s_address = payload?.data ?? [];
      state.send_main_address = pickMainAddress(payload?.data ?? []);
    },
    getSenderResponse2(state, action) {
      const { payload } = action;
      state.s_address2 = payload?.data ?? [];
      state.send_main_address2 = pickMainAddress(payload?.data ?? []);
    },
    startGetReceive(state) {
      state.r_address = [];
      state.receive_main_address = {};
    },
    startGetReceive2(state) {
      state.r_address2 = [];
      state.receive_main_address2 = {};
    },
    getReceiveResponse(state, action) {
      const { payload } = action;
      state.r_address = payload?.data ?? [];
      state.receive_main_address = pickMainAddress(payload?.data ?? []);
    },
    getReceiveResponse2(state, action) {
      const { payload } = action;
      state.r_address2 = payload?.data ?? [];
      state.receive_main_address2 = pickMainAddress(payload?.data ?? []);
    },
  },
});

export default slice.reducer;

export function getPartnerAddress(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Address.partnerAddress(params);
      dispatch(slice.actions.getAddressSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
}
export function getPartnerAddressType(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetPartnerAddress());
    try {
      const result = await Address.partnerAddress(removeIdentity(params));
      dispatch(slice.actions.getPartnerAddressSuccess(result));
    } catch (error) {
      console.log(error);
    }
  };
}
export function doUpdateProvince(data) {
  return async (dispatch) => {
    dispatch(slice.actions.updateProvinceData(data));
  };
}

export function getInfoAddress2(params, type = 1) {
  return async (dispatch) => {
    dispatch(
      type === SEND_ADDRESS
        ? slice.actions.startGetSender2()
        : slice.actions.startGetReceive2()
    );
    try {
      const result = await Address.partnerAddress(params);
      dispatch(
        type === SEND_ADDRESS
          ? slice.actions.getSenderResponse2(result)
          : slice.actions.getReceiveResponse2(result)
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getInfoAddress(params, type = 1) {
  return async (dispatch) => {
    dispatch(
      type === SEND_ADDRESS
        ? slice.actions.startGetSender()
        : slice.actions.startGetReceive()
    );
    try {
      const result = await Address.partnerAddress(params);
      dispatch(
        type === SEND_ADDRESS
          ? slice.actions.getSenderResponse(result)
          : slice.actions.getReceiveResponse(result)
      );
    } catch (error) {
      console.log(error);
    }
  };
}
