const _env = {
  production: "production",
  development: "development",
  local: "local",
};

const _app_url = {
  production: "https://online.ntx.com.vn/",
  development: "https://onlinedev.ntx.com.vn/",
  local: "http://api.ntex.me/",
};

const _api_url = {
  production: "https://bws.ntx.com.vn/",
  development: "https://bwsdev.ntx.com.vn/",
  local: "http://localhost:8081/",
};
const _web_url = {
  production: "https://ntx.com.vn/",
  development: "https://webdev.ntx.com.vn/",
  local: "https://ntx.com.vn/",
};
const _api_sandox = {
  production: "https://apiws.ntx.com.vn/",
  development: "https://apisandbox.ntx.com.vn/",
  local: "https://apisandbox.ntx.com.vn/",
};

const _cdn_url = {
  production: "https://cdn.ntx.com.vn/",
  development: "https://cdndev.ntlogistics.vn/",
  local: "https://cdndev.ntlogistics.vn/",
};

const _os_key = {
  production: "8727f910-944c-4ae9-ab60-c9a473ba5720",
  development: "ad423bf7-4fe0-4c30-ad2e-c17cf78de9a3",
  local: "ad423bf7-4fe0-4c30-ad2e-c17cf78de9a3",
};

const _eapi_url = {
  production: "https://eapi.ntx.com.vn/",
  development: "https://eapidev.ntx.com.vn/",
  local: "https://eapidev.ntx.com.vn/",
};

export const ENV = _env[window._env_.REACT_APP_STAGE];
export const API_URL = _api_url[window._env_.REACT_APP_STAGE];
export const APP_URL = _app_url[window._env_.REACT_APP_STAGE];
export const NTX_URL = _web_url[window._env_.REACT_APP_STAGE];
export const SAND_BOX = _api_sandox[window._env_.REACT_APP_STAGE];
export const CDN_URL = _cdn_url[window._env_.REACT_APP_STAGE];
export const OS_APPID = _os_key[window._env_.REACT_APP_STAGE];
export const EAPI_URL = _eapi_url[window._env_.REACT_APP_STAGE];
