import { PAGING_DEFAULT } from "constants/variables";
import { postMethod, User } from "utils";
import { Request } from "./https";

export const ShopService = {
  getStore: (params) => {
    const newRequest = new Request(`get`, `store`, params);
    return newRequest.sendXHR();
  },
  getStoreLocal: () => {
    return new Promise((resolve, reject) => {
      const config = User.permissions();
      const storeDB = config?.info ? [config?.info] : [];
      const successObject = {
        message: "Success",
        data: storeDB,
        success: true,
        paging: PAGING_DEFAULT,
      };
      resolve(successObject);
    });
  },
  createStore: (params) => {
    const newRequest = new Request(`post`, `store`, postMethod(params));
    return newRequest.sendXHR();
  },
  updateStore: (id, params) => {
    const url = `store/${id}`;
    const newRequest = new Request(`put`, url, postMethod(params));
    return newRequest.sendXHR();
  },
  getPermission: (params) => {
    const newRequest = new Request(`get`, `nte-permission`, params);
    return newRequest.sendXHR();
  },
  getPermissionLocal: () => {
    return new Promise((resolve, reject) => {
      const config = User.permissions();
      const editPerName = (i) =>
        (i = { ...i, permision_name: i.permission_name, id: i.permission_id });
      const permissionDB = config?.permission ? [config?.permission] : [];
      const successObject = {
        message: "Success",
        data: permissionDB.map(editPerName),
        success: true,
        paging: PAGING_DEFAULT,
      };
      resolve(successObject);
    });
  },
  createPermission: (params) => {
    const url = `nte-permission`;
    const newRequest = new Request(`post`, url, postMethod(params));
    return newRequest.sendXHR();
  },
  updatePermission: (id, params) => {
    const url = `nte-permission/${id}`;
    const newRequest = new Request(`put`, url, postMethod(params));
    return newRequest.sendXHR();
  },
  getChildAccount: (params) => {
    const newRequest = new Request(`get`, `list-childPartner`, params);
    return newRequest.sendXHR();
  },
  createAccount: (params) => {
    const newRequest = new Request(`post`, `create-child`, params);
    return newRequest.sendXHR();
  },
  updateAccount: (id, params) => {
    const url = `switch-partnerData/${id}`;
    const newRequest = new Request(`put`, url, params);
    return newRequest.sendXHR();
  },
};
