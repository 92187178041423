import { createSlice } from "@reduxjs/toolkit";
import { Banner } from "services/Banner";

const initialState = {
  isLoading: false,
  banner: [],
  paging: [],
};

const slice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    startGetBanner(state) {
      state.isLoading = true;
      state.banner = [];
      state.paging = {};
    },
    resultBanner(state, action) {
      const { payload } = action;
      state.banner = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    doneGetting(state) {
      state.isLoading = false;
    },
  },
});
export default slice.reducer;

export function getBanner(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetBanner());
    try {
      const result = await Banner.getBanner(params);
      dispatch(slice.actions.resultBanner(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}
