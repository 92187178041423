import { postMethod, removeIdentity } from "utils";
import { Request } from "./https";

export const CareSoft = {
  request: (params) => {
    const url = `ticket-caresoft`;
    const newRequest = new Request("post", url, postMethod(params));
    return newRequest.sendXHR();
  },
  getPartnerInfoCrmCs: (params) => {
    const url = `getPartnerInfoCrmCs`;
    const fetchP = removeIdentity(params);
    const newRequest = new Request("get", url, fetchP);
    return newRequest.sendXHR();
  },
};
