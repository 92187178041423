import { Request } from "./https";

export const Statistics = {
  getListBillFeeStatistic: (params) => {
    const newRequest = new Request("get", `statistic-cod`, params);
    return newRequest.sendXHR();
  },
  excelCOD: (params) => {
    const newRequest = new Request("export", `excel-cod`, params);
    return newRequest.sendXHR();
  },
  excelFee: (params) => {
    const newRequest = new Request("export", `excel-fee`, params);
    return newRequest.sendXHR();
  },
  getStatisticCodByStatusBill: (params) => {
    const newRequest = new Request("get", `statistic-cod-status-bill`, params);
    return newRequest.sendXHR();
  },
  getCodPaymentList: (params) => {
    const newRequest = new Request("get", `get-codPaymentList`, params);
    return newRequest.sendXHR();
  },
  getCodPaymentDetail: (params) => {
    const newRequest = new Request("get", `get-codPaymentDetail`, params);
    return newRequest.sendXHR();
  },
  ExcelCodPaymentDetail: (params) => {
    const newRequest = new Request("export", `get-ExcelCodPaymentDetail`, params);
    return newRequest.sendXHR();
  },
  excelCodListDetail: (params) => {
    const newRequest = new Request("export", `get-ExcelCodPaymentAll`, params);
    return newRequest.sendXHR();
  },
  summaryBillByStatus: (params) => {
    const newRequest = new Request("get", `summary-billByStatus`, params);
    return newRequest.sendXHR();
  },
  billIssue: (params) => {
    const newRequest = new Request("get", `user-bill-issue`, params);
    return newRequest.sendXHR();
  },
  excelBillIssue: (params) => {
    const newRequest = new Request("export", `excel-bill-issue`, params);
    return newRequest.sendXHR();
  },
};
