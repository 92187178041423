import { createSlice } from "@reduxjs/toolkit";
import { CRM_INFO } from "constants/variables";
import pick from "lodash/pick";
import { CareSoft } from "services/CareSoftService";
import { removeIdentity } from "utils";

const initialState = {
  customer: {},
  latestBills: [],
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    startGetInfo(state) {
      state.customer = {};
      state.latestBills = [];
    },
    getResponsePartner(state, action) {
      const { payload } = action;
      state.customer = removeIdentity(pick(payload?.data, Object.keys(CRM_INFO)));
      state.latestBills = payload?.data?.lastestBills ?? [];
    },
  },
});

export default slice.reducer;

export function getPartnerInfo(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetInfo());
    try {
      const result = await CareSoft.getPartnerInfoCrmCs(params);
      dispatch(slice.actions.getResponsePartner(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}
export function resetPartnerInfo() {
  return (dispatch) => {
    dispatch(slice.actions.startGetInfo());
  };
}
