import { Request } from "./https";

export const BillService = {
  pricing: (params) => {
    const newRequest = new Request("post", `calc-feeService`, params);
    return newRequest.sendXHR();
  },
  pushRmq: (params) => {
    const newRequest = new Request("post", "push-RMQBill", params);
    return newRequest.sendXHR();
  },
  pushCaresoft: (params) => {
    const newRequest = new Request("post", `bill-caresoft`, params);
    return newRequest.sendXHR();
  },
  pushRevokeRmq: (params) => {
    const newRequest = new Request("post", `create-billRecuperate`, params);
    return newRequest.sendXHR();
  },
  updateBill: (id, params) => {
    const newRequest = new Request("put", `bill-express/${id}`, params);
    return newRequest.sendXHR();
  },
  express: (params) => {
    const newRequest = new Request("get", `bill-express`, params);
    return newRequest.sendXHR();
  },
  searchBillComplete: (params) => {
    const newRequest = new Request("post", "search-completeBill", params);
    return newRequest.sendXHR();
  },
  checkArea: (params) => {
    const newRequest = new Request("post", "check-supportArea", params);
    return newRequest.sendXHR();
  },
  applyPromote: (params) => {
    const newRequest = new Request("post", "check-coupon", params);
    return newRequest.sendXHR();
  },
  trackingBill: (do_code) => {
    const newRequest = new Request("get", `tracking-bill/${do_code}`, {});
    return newRequest.sendXHR();
  },
  imageIssueBill: (do_code, type) => {
    const newRequest = new Request("get", `get-issue-bill/${do_code}`, {
      img_type: type,
    });
    return newRequest.sendXHR();
  },
  issueDelivery: (do_code, params) => {
    const url = `listIssueDelivery/${do_code}`;
    const newRequest = new Request("get", url, params);
    return newRequest.sendXHR();
  },
  updateBills: (params) => {
    const newRequest = new Request("post", `update-statusByDO`, params);
    return newRequest.sendXHR();
  },
  pdfPrint: (params) => {
    const url = `pdf-bill-express-byDoCode`;
    const newRequest = new Request("export", url, params);
    return newRequest.sendXHR();
  },
  pdfBillCp: (params) => {
    const newRequest = new Request("export", `pdf-bill-express`, params);
    return newRequest.sendXHR();
  },
  excelExpress: (params) => {
    const newRequest = new Request("export", "excel-bill-express-mg", params);
    return newRequest.sendXHR();
  },
  billByDoCode: (doCode) => {
    const newRequest = new Request("get", `bill-byDoCode/${doCode}`, {});
    return newRequest.sendXHR();
  },
  sendTicket: (doCode, params) => {
    const url = `request-sendTicket/${doCode}`;
    const newRequest = new Request("put", url, params);
    return newRequest.sendXHR();
  },
  tickets: (params) => {
    const newRequest = new Request("get", `list-requestTicketAll`, params);
    return newRequest.sendXHR();
  },
  cancelTicket: (id, params) => {
    const url = `cancel-ticket/${id}`;
    const newRequest = new Request("put", url, params);
    return newRequest.sendXHR();
  },
  deliveryApartUpdate: (params) => {
    const newRequest = new Request("post", `change-statusPartBill`, params);
    return newRequest.sendXHR();
  },
  deliveryApart: (params) => {
    const newRequest = new Request("get", `list-billPart`, params);
    return newRequest.sendXHR();
  },
  ticket: (requestKey) => {
    const url = `detail-TicketCode/${requestKey}`;
    const newRequest = new Request("get", url, {});
    return newRequest.sendXHR();
  },
  listBillPending: (params) => {
    const newRequest = new Request("get", `list-pendingBillRevert`, params);
    return newRequest.sendXHR();
  },
  totalBillFault: (params) => {
    const newRequest = new Request("get", `total-bill-issue-by-reason`, params);
    return newRequest.sendXHR();
  },
  approveBillPending: (params) => {
    const newRequest = new Request("post", `approved-RevertBill`, params);
    return newRequest.sendXHR();
  },
  sampleTemplate: (params) => {
    const _p = { type: "Bill", key: params };
    const newRequest = new Request("export", `get-template-bill`, _p);
    return newRequest.sendXHR();
  },

  exportExcelBillError: (params) => {
    const _p = { data: params };
    const newRequest = new Request("exportError", `excel-bill-error`, _p);
    return newRequest.sendXHR();
  },

  exportExcelMultiBillError: (params) => {
    const _p = { data: params };
    const newRequest = new Request(
      "exportError",
      `excel-bill-error-multiSender`,
      _p
    );
    return newRequest.sendXHR();
  },

  loadBillFromFile: (file, key) => {
    const newRequest = new Request("", `load-billFromFile`, {});
    newRequest.addConfigForm();
    newRequest.addFormRequest(file, key);
    return newRequest.importXHR();
  },
  loadBillRevokeFile: (file, key) => {
    const newRequest = new Request("", `load-billRevokeFile`, {});
    newRequest.addConfigForm();
    newRequest.addFormRequest(file, key);
    return newRequest.importXHR();
  },
  loadBillReceiverFile: (file, key) => {
    //const newRequest = new Request("", `load-billReceiverFile`, {});
    const newRequest = new Request("", `load-billFromFile`, {});

    newRequest.addConfigForm();
    newRequest.addFormRequest(file, key);
    return newRequest.importXHR();
  },
  verifyBillError: (params) => {
    const newRequest = new Request(`post`, `verify-billError`, params);
    return newRequest.sendXHR();
  },
  sampleTemplateV2: (params) => {
    const _p = { type: "Bill", key: params };
    const newRequest = new Request("export", `get-template-bill-version-2`, _p);
    return newRequest.sendXHR();
  },
  csFindBillByPhone: (params) => {
    const newRequest = new Request("post", `cs-findBillByPhone`, params);
    return newRequest.sendXHR();
  },
  detailTicket: (ticket) => {
    const newRequest = new Request("get", `detail-ticket/${ticket}`, {});
    return newRequest.sendXHR();
  },
  dashboard: (params) => {
    const newRequest = new Request("get", `ntx-dashboard-v2`, params);
    return newRequest.sendXHR();
  },
  getListComplain: (params) => {
    const newRequest = new Request("get", `get-ticketSent`, params);
    return newRequest.sendXHR();
  },
};
