import { ShopService } from "services/ShopService";
import { createSlice } from "@reduxjs/toolkit";
import { ACTIVE } from "constants/variables";

const initialState = {
  partner_store: [],
  partner_permission: [],
};
const makeStoreInfo = (i) =>
  (i = {
    ...i,
    store_info: `${i.store_name} - ${i.address}, ${i.ward_name}, ${i.district_name}, ${i.province_name}`,
  });
const slice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    startGetStore(state) {
      state.partner_store = [];
    },
    startGetPermission(state) {
      state.partner_permission = [];
    },
    resultParterStore(state, action) {
      const { payload } = action;
      state.partner_store = (payload?.data ?? []).map(makeStoreInfo);
    },
    resultParterPermission(state, action) {
      const { payload } = action;
      state.partner_permission = payload?.data ?? [];
    },
  },
});
export default slice.reducer;

export function getPartnerStore() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetStore());
    try {
      const result = await ShopService.getStore({ status: ACTIVE });
      dispatch(slice.actions.resultParterStore(result));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getPartnerPermission() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetPermission());
    try {
      const result = await ShopService.getPermission({ status: ACTIVE });
      dispatch(slice.actions.resultParterPermission(result));
    } catch (error) {
      console.log(error);
    }
  };
}
