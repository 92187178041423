import { START_REQUEST, GET_RESPONSE } from "constants/variables";

export const isLoadReducer = (
  state = {
    isProgress: false,
  },
  action
) => {
  switch (action.type) {
    case START_REQUEST:
      return {
        isProgress: true,
      };
    case GET_RESPONSE:
      return {
        isProgress: false,
      };
    default:
      return state;
  }
};

export default isLoadReducer;
