import { createSlice } from "@reduxjs/toolkit";
import { Payment } from "services/Payment";
import { User } from "utils";
import { ON } from "constants/variables";

const initialState = {
  isLoading: false,
  payment: [],
};
const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    startGetting(state) {
      state.isLoading = true;
      state.payment = [];
    },
    getPayment(state, action) {
      const { payload } = action;
      state.payment = payload?.data ?? [];
    },
    doneGetting(state) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

const paymentParams = () => {
  const ppCode = User.postpaidCode();
  const isHavePP = [ppCode, ppCode !== "null"].every(Boolean);
  if (isHavePP) {
    return {
      post_paid_code: ON,
    };
  }
  return {};
};

export function getPayment() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const params = paymentParams()
      const result = await Payment.get(params);
      dispatch(slice.actions.getPayment(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}
