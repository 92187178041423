import { postMethod } from "utils";
import { Request } from "./https";

export const BillFile = {
  getBillFile: (params) => {
    const newRequest = new Request("get", `/get-billFileHistory`, params);
    return newRequest.sendXHR();
  },
  getBillError: (params) => {
    const id = { bill_file_id: params, bill_status_id: 0 };
    const newRequest = new Request("get", `/bill-fileFailed`, id);
    return newRequest.sendXHR();
  },
  pdfBillFile: (params) => {
    const newRequest = new Request("export", `/pdf-billByFile`, params);
    return newRequest.sendXHR();
  },
  reCreateFailed: (params) => {
    let listItem = { listItem: params };
    const newRequest = new Request(
      `post`,
      `/bill-reCreateFailed`,
      postMethod(listItem)
    );
    return newRequest.sendXHR();
  },
};
