import axios from "axios";
import { returnLoginPath } from "utils";
import { API_URL } from "../config/Config";
import { TOKEN } from "../constants/AppConst";
import {
  SESSION_MESSAGE,
  START_REQUEST,
  GET_RESPONSE,
} from "../constants/variables";
import { store } from "store";

axios.defaults.baseURL = `${API_URL}v1`;
// const _uuid = localStorage.getItem("uuid");
// axios.defaults.headers.common["uuid"] = _uuid;
axios.interceptors.request.use(
  (config) => {
    store.dispatch({
      type: START_REQUEST,
    });
    const token = localStorage.getItem(TOKEN);
    const _uuid = localStorage.getItem("uuid");
    if (token) {
      config.headers["Authorization"] = token;
    }
    if (_uuid) {
      config.headers["uuid"] = _uuid;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    store.dispatch({
      type: GET_RESPONSE,
    });
    const message = response?.data?.message ?? "";
    const err = message.replace("!", "");
    const sessionOut = err === SESSION_MESSAGE;
    if (sessionOut) {
      returnLoginPath();
      return;
    }
    return response?.data ?? {};
  },
  (error) => {
    return Promise.reject(error);
  }
);
const config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
};
const request = {
  get: (url, params) => {
    return axios.get(url, { params: params, ...config });
  },
  post: (url, params) => {
    const _uuid = localStorage.getItem("uuid");
    if (_uuid) {
      config.headers["uuid"] = _uuid;
    }
    params.uuid = _uuid;
    return axios.post(url, params, config);
  },
  put: (url, params) => {
    const _uuid = localStorage.getItem("uuid");
    if (_uuid) {
      config.headers["uuid"] = _uuid;
    }
    params.uuid = _uuid;
    return axios.put(url, params, config);
  },
  export: (url, params) => {
    return axios({
      url: url,
      params: params,
      method: "GET",
      data: params,
      responseType: "blob", // important
    });
  },
  exportError: (url, params) => {
    return axios({
      url: url,
      method: "POST",
      data: params,
      responseType: "blob", // important
    });
  },
  import: (url, config, formData) => {
    return axios.post(url, formData, config);
  },
  post2: (url, params) => {
    const _uuid = localStorage.getItem("uuid");
    if (_uuid) {
      config.headers["uuid"] = _uuid;
    }
    return axios.post(url, params, config);
  },
};
export function Request(method = "get", url = "", params) {
  this.method = method ?? "get";
  this.params = params;
  this.url = url;
  this.config = {};
  this.formRequest = null;
  this.addConfigForm = function () {
    this.config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
  };
  this.addFormRequest = function (file, key) {
    let formData = new FormData();
    formData.append("bill_file", file);
    formData.append("key", key);
    this.formRequest = formData;
  };
  this.importXHR = function () {
    return request["import"](this.url, this.config, this.formRequest);
  };
  this.sendXHR = function () {
    return request[method](this.url, this.params);
  };
}
