import { createSlice } from "@reduxjs/toolkit";
import { Setting } from "services/Setting";
import {
  CARGO_SETTING_ID,
  PAYMENT_SETTING_ID,
  SETTING_ID,
  SERVICE_SETTING_ID,
  REVOKE_SETTING_ID,
  APART_SETTING_ID,
  YES,
  PRINT_SETTING_ID,
  GROUP_NAME,
  UNDELIVERY_SETTING_ID,
  RETURN_DOC_ID,
} from "constants/variables";
import groupBy from "lodash/groupBy";

const initialState = {
  isLoading: false,
  setting: [],
  cargoLimit: null,
  paymentLimit: [],
  serviceLimit: [],
  allowApart: null,
  undelivery_cash: null,
  allowRevoke: null,
  print_size: null,
  permissions: {},
  cargoContract: [],
  myNotify: [],
};
const getSettingValue = (arr = [], value) => {
  const checkInclude = value in (arr ?? []).objFromArray(SETTING_ID);
  if (!checkInclude) return value === CARGO_SETTING_ID ? undefined : [];
  return (arr ?? []).objFromArray(SETTING_ID)[value]?.main_value;
};
const isHaveConfig = (value, data) => {
  const obj = (data ?? []).objFromArray(SETTING_ID);
  const check = value in obj;
  if (!check) return check;
  return obj[value]?.main_value === YES;
};
const slice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    startGetting(state) {
      state.isLoading = true;
      state.setting = [];
      state.cargoLimit = null;
      state.paymentLimit = [];
      state.serviceLimit = [];
      state.allowApart = null;
      state.undelivery_cash = null;
      state.allowRevoke = null;
      state.print_size = null;
      state.cargoContract = [];
      state.myNotify = [];
      state.totalNotify = 0;
      state.paging = {};
    },
    startGetPermissions(state) {
      state.permissions = {};
    },
    startGetNotify(state) {
      state.myNotify = [];
    },
    startTotalNotify(state) {
      state.totalNotify = 0;
    },
    getTotalNotify(state, action) {
      const { payload } = action;
      state.totalNotify = payload?.data ?? 0;
    },
    getMyNotify(state, action) {
      const { payload } = action;
      state.myNotify = payload?.data ?? [];
      state.paging = payload?.paging ?? {};
    },
    getResultPermission(state, action) {
      const { payload } = action;
      state.permissions = groupBy(payload?.data ?? [], GROUP_NAME);
    },
    getSetting(state, action) {
      const { payload } = action;
      if (payload.success && payload.data) {
        state.cargoLimit = getSettingValue(payload?.data, CARGO_SETTING_ID);
        state.paymentLimit = getSettingValue(payload?.data, PAYMENT_SETTING_ID);
        state.serviceLimit = getSettingValue(payload?.data, SERVICE_SETTING_ID);
        state.cargoContract = getSettingValue(payload?.data, 25);
        state.allowApart = isHaveConfig(APART_SETTING_ID, payload?.data ?? []);
        state.undelivery_cash = isHaveConfig(
          UNDELIVERY_SETTING_ID,
          payload?.data ?? []
        );

        state.allowRevoke = isHaveConfig(
          REVOKE_SETTING_ID,
          payload?.data ?? []
        );
        state.print_size = getSettingValue(payload?.data, PRINT_SETTING_ID);
        state.is_return_doc = getSettingValue(payload?.data, RETURN_DOC_ID);
      }
      state.setting = payload?.data ?? [];
    },
    doneGetting(state) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export function getPartnerSetting() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await Setting.get({});
      dispatch(slice.actions.getSetting(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getPartnerPermission() {
  return async (dispatch) => {
    dispatch(slice.actions.startGetPermissions());
    try {
      const result = await Setting.getPermissions({});
      dispatch(slice.actions.getResultPermission(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getMyNotify(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetNotify());
    try {
      const result = await Setting.getMyNotify(params);
      dispatch(slice.actions.getMyNotify(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}

export function getTotalNotify(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startTotalNotify());
    try {
      const result = await Setting.getTotalNotify(params);
      dispatch(slice.actions.getTotalNotify(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.doneGetting());
    }
  };
}
