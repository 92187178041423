import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import logger from 'redux-logger'

export const history = createBrowserHistory()

export default function configureStore() {
  const middlewares = [thunk, logger]
  const composeEnhancer = compose;
  const store = createStore(
      createRootReducer(history),
      composeEnhancer(applyMiddleware(...middlewares)),
  )

  return store
}

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();

const store = configureStore();

export { store, useSelector, useDispatch  }
