import { createSlice } from "@reduxjs/toolkit";
import { BillService } from "services/BillService";
import { removeIdentity } from "utils";
import pick from "lodash/pick";
import { PARAMS_REQUEST } from "constants/variables";

const initialState = {
  ticket: [],
  list_complain: [],
  paging: {},
};
const slice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    startGetting(state) {
      state.ticket = [];
      state.list_complain = [];
      state.paging = {};
    },
    getTicketExpressSuccess(state, action) {
      const { payload } = action;
      state.ticket = payload.data;
      state.paging = payload.paging;
    },
    getListComplainSuccess(state, action) {
      const { payload } = action;
      state.list_complain = payload.data;
      state.paging = payload.paging;
    },
  },
});
export default slice.reducer;

export function getTicketExpress(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await BillService.tickets(
        removeIdentity(pick(params, PARAMS_REQUEST))
      );
      dispatch(slice.actions.getTicketExpressSuccess(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}

export function getListComplain(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startGetting());
    try {
      const result = await BillService.getListComplain(
        removeIdentity(pick(params, PARAMS_REQUEST))
      );
      dispatch(slice.actions.getListComplainSuccess(result));
    } catch (error) {
      console.log("res", error);
    }
  };
}
