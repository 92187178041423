import { createSlice } from "@reduxjs/toolkit";
import { Service } from "services/Service";
import { CargoService } from "services/CargoService";

const initialState = {
    isLoading: false,
    service: [],
    cargo: [],
};

const slice = createSlice({
    name: "service",
    initialState,
    reducers: {
        startGetting(state) {
            state.isLoading = true;
            state.service = [];
        },
        startGetService(state) {
            state.cargo = [];
        },
        getService(state, action) {
            const { payload } = action;
            state.service = payload?.data ?? [];
            //   state.service = (payload?.data ?? []).findAndRemove("HG", "service_code");
        },
        getCargo(state, action) {
            const { payload } = action;
            state.cargo = payload?.data ?? [];
        },
        doneGetting(state) {
            state.isLoading = false;
        },
    },
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function getService() {
    return async (dispatch) => {
        dispatch(slice.actions.startGetting());
        try {
            const result = await Service.get({});
            dispatch(slice.actions.getService(result));
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(slice.actions.doneGetting());
        }
    };
}
export function getCargo() {
    return async (dispatch) => {
        dispatch(slice.actions.startGetService());
        try {
            const result = await CargoService.get({});
            dispatch(slice.actions.getCargo(result));
        } catch (error) {
            console.log(error);
        }
    };
}
