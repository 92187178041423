import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*--------------------------REDUCERS------------------------------------------*/
import authSlice from "./slices/auth";
import serviceSlice from "./slices/service";
import payment from "./slices/payment";
import bill from "./slices/bill";
import address from "./slices/address";
import setting from "./slices/setting";
import isLoadReducer from "./loadReducer";
import statistics from "./slices/statistics";
import ticket from "./slices/ticket";
import bank from "./slices/bank";
import shop from "./slices/shop";
import customer from "./slices/customer";
import bilFile from "./slices/billFile";
import banner from "./slices/banner";

const createRootReducer = (history) =>
  combineReducers({
    authSlice,
    serviceSlice,
    payment,
    bill,
    address,
    setting,
    isLoadReducer,
    statistics,
    ticket,
    bank,
    shop,
    customer,
    bilFile,
    banner,
    router: connectRouter(history),
  });

export default createRootReducer;
