import { Request } from "./https";
import { User } from "utils";

export const Setting = {
  get: (_) => {
    const partner_id = User.id();
    const newRequest = new Request("get", `partner-setting`, {
      partner_id: partner_id,
    });
    return newRequest.sendXHR();
  },
  getSetting: (params) => {
    const newRequest = new Request("get", `setting`, params);
    return newRequest.sendXHR();
  },
  updatePartnerSetting: (id, params) => {
    const newRequest = new Request("put", `partner-setting/${id}`, params);
    return newRequest.sendXHR();
  },
  createPartnerSetting: (params) => {
    const newRequest = new Request("post", `partner-setting`, params);
    return newRequest.sendXHR();
  },
  addWebhook: (params) => {
    const newRequest = new Request("post", `add-webhook`, params);
    return newRequest.sendXHR();
  },
  addConfigPrint: (params) => {
    const newRequest = new Request("post", `add-configPrint`, params);
    return newRequest.sendXHR();
  },
  addConfigList: (params) => {
    const newRequest = new Request("post", `add-configList`, params);
    return newRequest.sendXHR();
  },
  getUser: (params) => {
    const newRequest = new Request("get", `currentUser`, params);
    return newRequest.sendXHR();
  },
  updateUser: (params) => {
    const newRequest = new Request("post", `update-CurrentUser`, params);
    return newRequest.sendXHR();
  },
  updatePartnerAddress: (id, params) => {
    const newRequest = new Request("put", `partner-address/${id}`, params);
    return newRequest.sendXHR();
  },
  createPartnerAddress: (params) => {
    const newRequest = new Request("post", `partner-address`, params);
    return newRequest.sendXHR();
  },
  getPermissions: (params) => {
    const newRequest = new Request("get", `nte_permissions`, params);
    return newRequest.sendXHR();
  },
  getNotifyGlobal: (params) => {
    const newRequest = new Request("get", `notify-global`, params);
    return newRequest.sendXHR();
  },
  createNotifyGlobal: (params) => {
    const newRequest = new Request("post", `notify`, params);
    return newRequest.sendXHR();
  },
  updateNotifyGlobal: (id, params) => {
    const newRequest = new Request("put", `notify/${id}`, params);
    return newRequest.sendXHR();
  },
  getMyNotify: (params) => {
    const newRequest = new Request("get", `notify`, params);
    return newRequest.sendXHR();
  },
  getTotalNotify: (params) => {
    const newRequest = new Request("get", `total-notify`, params);
    return newRequest.sendXHR();
  },
  updateMyNotify: (id, params) => {
    const newRequest = new Request("put", `notify/${id}`, params);
    return newRequest.sendXHR();
  },
  seenAllNotify: (params) => {
    const newRequest = new Request("post2", `seen-all-notify`, params);
    return newRequest.sendXHR();
  },
};
